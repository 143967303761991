import { Avatar, Button, Heading, HStack, Radio, RadioGroup, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useGetUserQuery, usePutUserMutation } from '../../services/api/api-user';
import { COMMON_LOCALES, ERROR_LOCALES, PROFILE_LOCALES } from '../../utils/constants';
import getApiErrorMessage from '../../utils/error-helpers';
import { TextField } from '../fields';
import UnclosableBanner from '../UnclosableBanner';

const Informations = () => {
    const { t } = useTranslation([PROFILE_LOCALES, ERROR_LOCALES]);
    const { data: user, isSuccess } = useGetUserQuery();

    const stringLanguage = i18next.language.startsWith('fr') ? 'fr' : 'en';

    const [putUser, { isSuccess: isSuccessPutUser, error }] = usePutUserMutation();

    const updateLanguage = async (newLanguage: string) => {
        await i18next.changeLanguage(newLanguage);
    };

    async function handleSubmit(values: {firstname: string, lastname: string}) {
        await putUser(values);
    }

    const InformationsSchema = Yup.object().shape({
        firstname: Yup.string()
            .required(t('required', { ns: 'errors' })),
        lastname: Yup.string()
            .required(t('required', { ns: 'errors' })),
    });

    return (
        <VStack align='start' bg="white" borderRadius="2xl" maxW="590px" p={8} spacing={8} sx={{ '&>form': { w: 'full' } }} w="full">
            {
                error
                && <UnclosableBanner description={getApiErrorMessage(error)} title={t('submit', { ns: ERROR_LOCALES })} type='error'/>
            }
            {
                isSuccessPutUser
                && <UnclosableBanner description={t('informations.success')} title={t('success', { ns: COMMON_LOCALES })} type='success'/>
            }
            <HStack spacing={6}>
                <Avatar boxSize={20} name={`${user?.firstname} ${user?.lastname}`} />
            </HStack>
            <VStack align='start'>
                <Heading fontSize="md">{t('informations.language.label')}</Heading>
                <RadioGroup
                    defaultValue={stringLanguage}
                    onChange={updateLanguage}
                    value={stringLanguage}
                >
                    <HStack align='start' spacing={5}>
                        <Radio value="en">{t('informations.language.english')}</Radio>
                        <Radio value="fr">{t('informations.language.french')}</Radio>
                    </HStack>
                </RadioGroup>
            </VStack>
            {
                isSuccess
                && <Formik
                    enableReinitialize
                    initialValues={{
                        firstname: `${user?.firstname}`,
                        lastname: `${user?.lastname}`,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={InformationsSchema}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <VStack align='start' spacing={6} w="full">
                                <HStack align='space-between'>
                                    <TextField
                                        label={t('informations.first_name.label')}
                                        name="firstname"
                                        placeholder={t('informations.first_name.placeholder')}
                                    />
                                    <TextField
                                        label={t('informations.last_name.label')}
                                        name="lastname"
                                        placeholder={t('informations.last_name.placeholder')}
                                    />
                                </HStack>

                                <Button isLoading={isSubmitting} type="submit" variant="solid">
                                    {t('informations.submit_label')}
                                </Button>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            }

        </VStack>
    );
};

export default Informations;
