import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addErrorImage, resetErrorImage } from '../services/store/slices/sessionSlice';
import { GarmentQuality, HDWarpQuality, Sample, SingleCutImages, WarpQuality } from '../types/api-types';
import { ERROR_LOCALES, LAYOUT_LOCALES } from './constants';

function preloadImage(src: string, onLoadCallback?: (imgSrc: string) => void, onErrorCallback?: (imgSrc: string) => void) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            if (onLoadCallback) {
                onLoadCallback(src);
            }
            resolve(img);
        };
        img.onerror = () => {
            if (onErrorCallback) {
                onErrorCallback(src);
            }

            reject(src);
        };
        img.onabort = img.onerror;

        img.src = src;
    });
}

// ---- Extracts every image used in Garment QC in an Array ----
export const getImagePreloadListFromGarmentQuality = (
    garmentDetail?: GarmentQuality,
) => {
    const imageList: string[] = [];

    const addToList = (newImage: string) => {
        if (imageList.includes(newImage)) {
            return;
        }
        imageList.push(newImage);
    };

    if (!garmentDetail) {
        return imageList;
    }

    addToList(garmentDetail.steps.clipping.image_url);
    addToList(garmentDetail.image_url);
    addToList(garmentDetail.steps.clipping.image_contrast_url);

    if (garmentDetail.steps.foreground && !Array.isArray(garmentDetail.steps.foreground)) {
        addToList(garmentDetail.steps.foreground.image_url);
        addToList(garmentDetail.steps.foreground.image_contrast_url);
    }

    if (garmentDetail.steps.cuts && !Array.isArray(garmentDetail.steps.cuts) && garmentDetail.steps.foreground) {
        Object.values(garmentDetail.steps.cuts).forEach(
            (cutsValue: SingleCutImages) => {
                addToList(cutsValue.image_url);
                if (cutsValue.image_contrast_url) {
                    addToList(cutsValue.image_contrast_url);
                }
            },
        );
    }

    if (garmentDetail.steps.mesh && !Array.isArray(garmentDetail.steps.mesh)) {
        addToList(garmentDetail.steps.mesh.image_url);
    }

    return imageList;
};

// ---- Extracts every image used in Warp QC in an Array ----
export const getImagePreloadListFromWarpQuality = (
    warpDetail?: WarpQuality, modelSamples?: Sample[], outfitSamples?: Sample[], hdDetail?: HDWarpQuality,
) => {
    const imageList: string[] = [];

    const addToList = (newImage: string) => {
        if (!newImage || imageList.includes(newImage)) {
            return;
        }
        imageList.push(newImage);
    };

    // ---- Warp Quality sources ----
    if (!warpDetail) {
        return imageList;
    }

    addToList(warpDetail.image_url);

    if (warpDetail.debugs.cross?.image_url) {
        addToList(warpDetail.debugs.cross?.image_url);
    }

    if (warpDetail.poses) {
        warpDetail.poses.forEach((pose) => {
            addToList(pose.image_url);
        });
    }

    if (warpDetail.variants) {
        warpDetail.variants.forEach((pose) => {
            addToList(pose.image_url);
        });
    }

    if (warpDetail.extras) {
        warpDetail.extras.forEach((extra) => {
            addToList(extra);
        });
    }

    if (warpDetail.reference) {
        addToList(warpDetail.reference.image_url);
    }

    if (warpDetail.debugs.smart) {
        addToList(warpDetail.debugs.smart.smart_image_url);
        addToList(warpDetail.debugs.smart.nosmart_image_url);
    }

    if (warpDetail.debugs.parts) {
        addToList(warpDetail.debugs.parts.parts_image_url);
        addToList(warpDetail.debugs.parts.torso_image_url);
    }

    addToList(warpDetail.debugs.transparent.model_image_url);
    addToList(warpDetail.debugs.transparent.garment_image_url);
    addToList(warpDetail.debugs.grid.image_url);
    addToList(warpDetail.debugs.contrast.image_url);

    if (warpDetail.debugs.garmentcut) {
        addToList(warpDetail.debugs.garmentcut.nogarmentcut_image_url);
    }

    // ---- Samples source ----
    if (modelSamples) {
        modelSamples.forEach((sample) => {
            addToList(sample.image_url);
        });
    }

    if (outfitSamples) {
        outfitSamples.forEach((sample) => {
            addToList(sample.image_url);
        });
    }

    if (hdDetail) {
        addToList(hdDetail.image_hd_url);
        addToList(hdDetail.debugs.transparent.model_image_url);
        addToList(hdDetail.debugs.transparent.garment_image_url);
        addToList(hdDetail.debugs.contrast.image_url);
    }

    return imageList;
};

export default function useImagePreloader(imageList: string[]) {
    const dispatch = useDispatch();
    const { t } = useTranslation([LAYOUT_LOCALES, ERROR_LOCALES]);
    const { pathname } = useLocation();

    const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);
    const [imagesPreloadedList, setImagesPreloadedList] = useState<string[]>([]);

    let counterImgLoad = 0;
    let counterImgError = 0;
    let isCancelled = false;

    // Changes the tab title according to loading finished state
    const handleTitleChange = (hasError: boolean, nbErrors?: number) => {
        if (hasError) {
            document.title = `${nbErrors ? `${nbErrors} ` : ''}${t('doc_title', { ns: ERROR_LOCALES })}`;

            return;
        }

        const pagePath = pathname.split('/')[1] || 'home';
        const title = t(`page_names.${pagePath}`);
        if (title === `page_names.${pagePath}`) {
            document.title = 'Veesual IGP';

            return;
        }

        document.title = `${title} | Veesual IGP`;
    };

    const updateTitle = () => {
        document.title = `${
            counterImgError > 0
                ? `${counterImgError} ${t('error', { ns: ERROR_LOCALES })} - `
                : ''}${counterImgLoad}/${imageList.length} ${t('page_names.loading')}`;
    };

    const loadingCounterIncrement = () => {
        if (isCancelled) {
            return;
        }

        counterImgLoad++;
        updateTitle();
    };

    const onImageLoaded = (imgSrc: string) => {
        setImagesPreloadedList((prev) => [...prev, imgSrc]);
        loadingCounterIncrement();
    };

    const onImageError = (imgSrc: string) => {
        if (isCancelled) {
            return;
        }

        dispatch(addErrorImage(imgSrc));
        counterImgError++;
        updateTitle();
    };

    useEffect(() => {
        async function effect() {
            if (isCancelled) {
                return;
            }

            document.title = `${counterImgLoad}/${imageList.length} ${t('page_names.loading')}`;

            const imagesPromiseList: Promise<unknown>[] = [];
            for (const i of imageList) {
                imagesPromiseList.push(preloadImage(i, onImageLoaded, onImageError));
            }

            // ---- Using allSettled and cheking after if we had an arror to change the title according the result array ----
            const promiseResults = await Promise.allSettled(imagesPromiseList);
            if (!isCancelled) {
                handleTitleChange(!!promiseResults.find((result) => result.status === 'rejected'), counterImgError);
            }

            counterImgLoad = 0;
            counterImgError = 0;

            if (isCancelled) {
                return;
            }

            setImagesPreloaded(true);
        }

        effect().catch((err) => {
            console.error(err);
        });

        return () => {
            isCancelled = true;
            dispatch(resetErrorImage());
        };
    }, [imageList]);

    return { imagesPreloaded, imagesPreloadedList };
}
