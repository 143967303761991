import { Dispatch } from '@reduxjs/toolkit';

import { setAuthenticated } from '../services/store/slices/sessionSlice';
import { LoginResponse } from '../types/api-types';
import { removeTokens, storeTokens } from './auth-helpers';
import { LOGOUT_ACTION } from './constants';

/**
 * Logout handling method
 * @param dispatch Redux dispatch method because we can't useDispatch here
 */
export const handleLogout = (dispatch: Dispatch) => {
    removeTokens();
    dispatch(LOGOUT_ACTION);
    dispatch(setAuthenticated(false));
};

/**
 * Login handling method
 * @param dispatch Redux dispatch method because we can't useDispatch here
 * @param tokens New token we get from login in
 */
export const handleLogin = (dispatch: Dispatch, tokens: LoginResponse) => {
    storeTokens(tokens);
    dispatch(setAuthenticated(true));
};
