import { createStandaloneToast } from '@chakra-ui/react';
import { Action, combineReducers, configureStore, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import theme from '../../theme';
import { ErrorMutation } from '../../types/api-types';
import handleSentryApiError from '../../utils/sentry-helpers';
import { apiAuth } from '../api/api-auth';
import { apiCheck } from '../api/api-check';
import { apiClient } from '../api/api-client';
import { apiDashboard } from '../api/api-dashboard';
import { apiExternal } from '../api/api-external';
import { apiFeedback } from '../api/api-feedback';
import { apiGarment } from '../api/api-garment';
import { apiImage } from '../api/api-image';
import { apiUser } from '../api/api-user';
import { apiWarp } from '../api/api-warp';
import { apiWarpEdit } from '../api/api-warp-edit';
import sessionSliceReducer from './slices/sessionSlice';

const combinedReducer = combineReducers({
    [apiUser.reducerPath]: apiUser.reducer,
    [apiAuth.reducerPath]: apiAuth.reducer,
    [apiClient.reducerPath]: apiClient.reducer,
    [apiGarment.reducerPath]: apiGarment.reducer,
    [apiWarp.reducerPath]: apiWarp.reducer,
    [apiFeedback.reducerPath]: apiFeedback.reducer,
    [apiWarpEdit.reducerPath]: apiWarpEdit.reducer,
    [apiCheck.reducerPath]: apiCheck.reducer,
    [apiExternal.reducerPath]: apiExternal.reducer,
    [apiDashboard.reducerPath]: apiDashboard.reducer,
    [apiImage.reducerPath]: apiImage.reducer,
    session: sessionSliceReducer,
});

/**
 * Show a toast on query error!
 */
const previousToast: {timestamp: number, title?: string} = { timestamp: 0, title: '' };
const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    const { toast } = createStandaloneToast({ theme });

    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        // is this a duplicate
        const timestamp = Date.now();
        const title = (action.payload as ErrorMutation).data?.message || action.payload.error || (action.payload.data as string);
        if (previousToast.title !== title || (timestamp - previousToast.timestamp) >= 1000) {
            // ---- We don't show a toast if it's a postWarpEdit request with a urlPrefix because this request will be done again ----
            if (action.meta?.arg?.endpointName === 'postWarpEdit' && action.meta?.arg?.originalArgs?.urlPrefix) {
                return next(action);
            }

            toast({
                isClosable: true,
                status: 'error',
                title,
            });

            // ---- Sentry Log ----
            handleSentryApiError(action.meta?.arg?.endpointName);
        }

        // keep track of the last
        previousToast.timestamp = timestamp;
        previousToast.title = title;
    }

    return next(action);
};

// Root reducer that listen to every action dispatched
const rootReducer = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state: any, action: Action,
) => combinedReducer(action.type === 'logout' ? undefined : state, action);

export const store = configureStore({
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        apiUser.middleware,
        apiAuth.middleware,
        apiClient.middleware,
        apiGarment.middleware,
        apiWarp.middleware,
        apiFeedback.middleware,
        apiWarpEdit.middleware,
        apiCheck.middleware,
        apiExternal.middleware,
        apiDashboard.middleware,
        apiImage.middleware,
        rtkQueryErrorLogger,
    ),

    reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

// Export the typed useSelector hook to use in react component
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
