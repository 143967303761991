import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { EmailField } from '../components/fields';
import UnclosableBanner from '../components/UnclosableBanner';
import { useForgotPasswordMutation } from '../services/api/api-auth';
import { ERROR_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';
import getApiErrorMessage from '../utils/error-helpers';

const ForgotPassword = () => {
    const [forgot, { error, isSuccess }] = useForgotPasswordMutation();
    const navigate = useCustomNavigate();

    const { t } = useTranslation(['auth', 'errors']);

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('email.invalid', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
    });

    async function handleSubmit(values: {email: string}) {
        await forgot({ username: values.email });
    }

    return (
        <>
            <Flex boxSize="full" minH="520px">
                <VStack justify="center" spacing={8} w={{ base: 'full', lg: '60vw' }}>
                    <VStack align="flex-start">
                        <Button
                            fontWeight={500}
                            leftIcon={<ChevronLeftIcon boxSize={6} />}
                            onClick={() => navigate('/login')}
                            variant="link"
                        >
                            {t('forgot_password.back_to_login_label')}
                        </Button>
                        <VStack
                            align="flex-start"
                            bg="white"
                            borderRadius="2xl"
                            px={8} py={10}
                            spacing={10}
                            sx={{ '&>form': { w: 'full' } }}
                            w="590px"
                        >
                            {/* CHANGE LOCALES */}
                            {error
                                && <UnclosableBanner
                                    description={getApiErrorMessage(error)}
                                    title={t('submit', { ns: ERROR_LOCALES })}
                                    type="error"
                                />
                            }
                            {
                                isSuccess
                                    ? <UnclosableBanner
                                        description={t('forgot_password.success.description')}
                                        title={t('forgot_password.success.title')}
                                        type="success"
                                    />
                                    : <>
                                        <VStack align="flex-start" spacing={2} w="full">
                                            <Heading color="black" fontSize="2xl">{t('forgot_password.title')}</Heading>
                                            <Text>{t('forgot_password.subtitle')}</Text>
                                        </VStack>
                                        <Formik
                                            initialValues={{
                                                email: '',
                                            }}
                                            onSubmit={handleSubmit}
                                            validationSchema={ForgotPasswordSchema}
                                        >
                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <VStack spacing={10} w="full">
                                                        <VStack align="flex-end" spacing={6} w="full">
                                                            <EmailField
                                                                label={t('forgot_password.email.label')}
                                                                name="email"
                                                                placeholder={t('forgot_password.email.placeholder')}
                                                            />
                                                        </VStack>
                                                        <Button
                                                            isLoading={isSubmitting}
                                                            type="submit"
                                                            variant="solid"
                                                            w="full">{t('forgot_password.submit_label')}
                                                        </Button>
                                                    </VStack>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                            }
                        </VStack>
                    </VStack>
                </VStack>
            </Flex>
            <Image
                bottom={0}
                boxSize="full"
                display={{ base: 'none', lg: 'block' }}
                fit="contain"
                maxW="35vw"
                pointerEvents="none"
                position="fixed"
                right={0}
                src="/assets/illustrations/heroes.svg"
                top={0}
            />
        </>
    );
};

export default ForgotPassword;
