import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import i18n from '../i18n/config';
import { ErrorMutation } from '../types/api-types';
import { ERROR_LOCALES } from './constants';

// ---- Get the message from an RTK Api error or returns a fallback ----
const getApiErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
    let errorMessage: string | undefined;

    // ---- Type narrowing and message extraction ----
    if ('status' in error) {
        errorMessage = (error as ErrorMutation).data?.message;
    } else {
        errorMessage = error.message;
    }

    // ---- If we didn't get the message from the error and the translation module is initialized  we set the fallback ----
    if (!errorMessage && i18n.isInitialized) {
        errorMessage = i18n.t('unknown', { ns: ERROR_LOCALES });
    }

    return errorMessage;
};

export default getApiErrorMessage;
