import * as Sentry from '@sentry/react';

const handleSentryApiError = (endpointName?: string) => {
    // ---- Var init ----
    const error = new Error();

    // ---- We assign the name of the error so it is set as the Sentry Issue title ----
    error.name = `API Error on ${endpointName}` || 'API Unknown Endpoint Error';

    // ---- Send data to Sentry ----
    Sentry.captureException(error, { level: 'warning', tags: { apiError: true } });
};

export default handleSentryApiError;
