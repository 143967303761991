import { Button, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useChangePasswordMutation } from '../../services/api/api-user';
import { COMMON_LOCALES, ERROR_LOCALES, PROFILE_LOCALES } from '../../utils/constants';
import getApiErrorMessage from '../../utils/error-helpers';
import { PasswordField } from '../fields';
import UnclosableBanner from '../UnclosableBanner';

const Password = () => {
    const { t } = useTranslation([PROFILE_LOCALES, ERROR_LOCALES]);

    const [changePassword, { isSuccess, error }] = useChangePasswordMutation();

    const PasswordSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .min(8, t('password.too_short', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
        newPassword: Yup.string()
            .min(8, t('password.too_short', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
    });

    async function handleSubmit(values: {currentPassword: string, newPassword: string}) {
        await changePassword({ newPassword: values.newPassword, oldPassword: values.currentPassword });
    }

    return (
        <VStack align='start' bg="white" borderRadius="2xl" maxW="590px" p={8} spacing={8} sx={{ '&>form': { w: 'full' } }} w="full">
            {
                error
                && <UnclosableBanner description={getApiErrorMessage(error)} title={t('submit', { ns: ERROR_LOCALES })} type='error'/>
            }
            {
                isSuccess
                && <UnclosableBanner description={t('password.success')} title={t('success', { ns: COMMON_LOCALES })} type='success'/>
            }
            <Formik
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={PasswordSchema}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <VStack align='start' spacing={10} w="full">
                            <VStack align="flex-end" spacing={6} w="full">
                                <PasswordField
                                    label={t('password.current_password.label')}
                                    name="currentPassword"
                                    placeholder={t('password.current_password.placeholder')}
                                />
                                <PasswordField
                                    label={t('password.new_password.label')}
                                    name="newPassword"
                                    placeholder={t('password.new_password.placeholder')}
                                />
                            </VStack>
                            <Button isLoading={isSubmitting} type="submit" variant="solid">{t('password.submit_label')}</Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </VStack>
    );
};

export default Password;
