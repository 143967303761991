import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Avatar,
    Button,
    Container,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spinner,
    Tab,
    TabList,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { OnChangeValue } from 'react-select';

import { VeesualSquare } from '../../assets/icons';
import {
    useGetClientConfigQuery,
    useGetClientExperiencesQuery,
    useGetClientsQuery,
} from '../../services/api/api-client';
import { useGetUserQuery } from '../../services/api/api-user';
import {
    getClient,
    getExperience,
    isClientInitialized,
    setClient,
    setConfig,
    setExperience,
    setUser,
} from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ClientResponse, Experience } from '../../types/api-types';
import preventCmdCtrlClick from '../../utils/click-helpers';
import { COMMON_LOCALES, ERROR_LOCALES, LAYOUT_LOCALES, MAIN_HEADER_HEIGHT } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import getApiErrorMessage from '../../utils/error-helpers';
import { handleLogout } from '../../utils/login-helpers';
import useRoles from '../../utils/roles-hook';
import { getCleanSearch } from '../../utils/url-helpers';
import CustomDropdown from '../CustomDropdown';
import BlockNavModal from '../modals/BlockNavModal';
import usePermission from '../permission/usePermission';

const PLATFORM_MODE = [{ label: 'Garment', value: 'garment' }, { label: 'Model', value: 'model' }];

const AuthLayout = () => {
    const { pathname, search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation([LAYOUT_LOCALES, COMMON_LOCALES]);
    const { isAdmin, isNoRole } = useRoles();

    const { data: user, isSuccess: isUserSuccess, error: errorUser } = useGetUserQuery();
    const currentClient = useAppSelector((state) => getClient(state));
    const isClientInit = useAppSelector((state) => isClientInitialized(state));
    const selectedExperience = useAppSelector((state) => getExperience(state));

    // Clients are used to restrict routes if the list is empty or null
    const { data: clients, error: errorClient } = useGetClientsQuery();

    const { data: config, error: errorConfig } = useGetClientConfigQuery({
        clientId: currentClient ? currentClient.id : '',
    }, { skip: !isClientInit || !currentClient });

    const { data: experiences } = useGetClientExperiencesQuery(
        currentClient ? currentClient.id : '',
        { skip: !isClientInit || !currentClient || currentClient?.internal === '' },
    );

    // ---- Tabs Permission Hook ----
    const inputAllowed = usePermission('input');
    const preprocessingAllowed = usePermission('preprocessing');
    const refwarpAllowed = usePermission('ref_warp.read');
    const crosswarpAllowed = usePermission('crosswarp.read');

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [configError, setConfigError] = useState<string>();

    const [mode, setMode] = useState<{ label: string, value: string } | null>();

    const topMenuHeight = `${MAIN_HEADER_HEIGHT}px`;

    function resetQcParams() {
        searchParams.delete('step');
        searchParams.delete('garmentId');
        setSearchParams(searchParams);
    }

    const currentTab = useMemo(() => {
        switch (true) {
            case pathname === '/input':
                return 0;
            case pathname === '/preprocessing' || !!matchPath('/preprocessing/:garmentId/quality', pathname):
                return 1;
            case pathname === '/refwarp' || !!matchPath('/refwarp/:warpId/quality', pathname):
                return 2;
            case pathname === '/crosswarp'
                || !!matchPath('/crosswarp/:warpId/quality', pathname)
                || !!matchPath('/crosswarp/:warpId', pathname):
                return 3;
            case pathname === '/external_validation':
                return 4;
            case pathname === '/check':
                return 5;
            default:
                return -1;
        }
    }, [pathname]);

    // ---- Main useEffect ----
    useEffect(() => {
        // ---- We get the current Search Parameters ----
        const clientId = searchParams.get('clientId');
        const experienceId = searchParams.get('experienceId');

        // ---- If there is an error on a request we set the error message to stop the app ----
        if (errorConfig && !configError) {
            setConfigError(getApiErrorMessage(errorConfig));
            setIsLoading(false);

            return undefined;
        }
        if (errorClient && !configError) {
            setConfigError(getApiErrorMessage(errorClient));
            setIsLoading(false);

            return undefined;
        }
        if (errorUser && !configError) {
            setConfigError(getApiErrorMessage(errorUser));
            setIsLoading(false);

            return undefined;
        }

        // ---- Reset errors ----
        setConfigError(undefined);

        // ---- Default Loading ----
        setIsLoading(true);

        // ---- The clients are the root of data we need ----
        if (!clients) {
            return undefined;
        }

        // ---- Client ID Handling ----
        if (clientId) {
            // ---- When the clientId doesn't match the currentClient we set the right data----
            if (clientId !== currentClient?.id) {
                const newClient = clients.find((client) => client.id === clientId) || null;
                if (!newClient) {
                    // ---- We remove the wrong clientId from the URL ----
                    searchParams.delete('clientId');
                    setSearchParams(searchParams);

                    return undefined;
                }
                dispatch(setClient(newClient));

                // ---- Prevents wrong config when we skip the config request or because it's already cached ----
                dispatch(setConfig(config || null));

                return undefined;
            }

            // ---- We set a default client if we don't have a client ----
        } else if (clients.length > 0) {
            searchParams.set('clientId', clients[0].id);
            setSearchParams(searchParams);

            return undefined;
        }

        // ---- We do nothing if there are no experiences ----
        if (experiences === undefined) {
            return undefined;
        }

        // ---- Experience ID Handling ----
        if (experiences.length > 0) {
            const foundExperience = experiences.find((localExp) => localExp.id === experienceId || (localExp.id === '' && experienceId === null));
            if (foundExperience) {
                if (foundExperience !== selectedExperience) {
                    dispatch(setExperience(foundExperience));

                    return undefined;
                }
            } else if (experiences[0].clients?.find((localClient) => localClient.id === currentClient?.id)) {
                if (experiences[0].id) {
                    searchParams.set('experienceId', experiences[0].id);
                    setSearchParams(searchParams, { replace: true });
                }
                dispatch(setExperience(experiences[0]));

                return undefined;
            }
        }

        // ---- Check if all data is correct to stop the loading ----
        if (
            // ---- Client verification ----
            (!currentClient || currentClient.id === config?.client_id)
        ) {
            setIsLoading(false);

            return undefined;
        }

        return undefined;
    }, [searchParams, config, currentClient, clients, experiences, selectedExperience, errorConfig, errorClient, errorUser]);

    useEffect(() => {
        // ---- Initial mode value if in URL ----
        const modeValue = searchParams.get('mode');
        if (modeValue) {
            return setMode(PLATFORM_MODE.find((localMode) => localMode.value === modeValue));
        }

        return setMode(PLATFORM_MODE[0]);
    }, []);

    useEffect(() => {
        dispatch(setConfig(config || null));
    }, [config]);

    useEffect(() => {
        const pagePath = pathname.split('/')[1] || 'home';

        const title = t(`page_names.${pagePath}`);

        if (title === `page_names.${pagePath}`) {
            document.title = 'Veesual IGP';
        } else {
            document.title = `${title} | Veesual IGP`;
        }
    }, [pathname]);

    useEffect(() => {
        if (mode) {
            searchParams.set('mode', mode.value);
            setSearchParams(searchParams, { replace: true });
        }
    }, [mode]);

    // ---- We store the user whenever we fetch it ----
    useEffect(() => {
        if (user) {
            dispatch(setUser(user));
        }
    }, [user]);

    function clickLogout() {
        handleLogout(dispatch);
        navigate('/login');
    }

    // ---- Handling of click on a tab ----
    function handleTabChange(index: number) {
        switch (index) {
            case 0:
                return navigate('/input');
            case 1:
                return navigate('/preprocessing');
            case 2:
                return navigate('/refwarp');
            case 3:
                return navigate('/crosswarp');
            case 4:
                return navigate('/external_validation');
            case 5:
                return navigate('/check');
            default:
                return null;
        }
    }

    function handleSelectDomain(option: OnChangeValue<ClientResponse, false>) {
        if (!option || option.id === currentClient?.id) {
            return;
        }

        // We remove the experience here so it doesn't do a request with wrong experience ID
        dispatch(setExperience(null));

        searchParams.set('clientId', option.id);
        searchParams.delete('experienceId');
        setSearchParams(searchParams);
    }

    function handleSelectExperience(option: OnChangeValue<Experience, false>) {
        if (!option || option.id === selectedExperience?.id) {
            return;
        }

        if (option.id) {
            searchParams.set('experienceId', option.id);
        } else {
            searchParams.delete('experienceId');
        }
        setSearchParams(searchParams);

        resetQcParams();
    }

    function handleModeChange(option: OnChangeValue<{ label: string, value: string }, false>) {
        if (!option) {
            return;
        }

        setMode(option);
    }

    function handleLogoClick() {
        navigate('/');
    }

    function handleRenderContent() {
        // If the request is not loading and did not succeed
        if (configError) {
            return (
                <VStack alignItems="center" h="100%" justifyContent="center" w="100%">
                    <Text>{t('network.default', { ns: ERROR_LOCALES })} {configError}</Text>
                    <Button onClick={() => window.location.reload()} variant="link">{t('retry', { ns: COMMON_LOCALES })}</Button>
                </VStack>
            );
        }

        // If we are loading we show a spinner
        if (isLoading) {
            return <VStack alignItems="center" h="100%" justifyContent="center" w="100%"><Spinner /></VStack>;
        }

        // ---- Block if we don't have matching param between searchParams and store ----
        if (!currentClient
            || searchParams.get('clientId') !== currentClient.id
            || (selectedExperience && selectedExperience.id !== '' && searchParams.get('experienceId') !== selectedExperience.id)
            || (!selectedExperience?.id && searchParams.get('experienceId'))
        ) {
            return <VStack alignItems="center" h="100%" justifyContent="center" w="100%"><Spinner /></VStack>;
        }

        return <Outlet />;
    }

    return (
        <Flex bg="gray.50" direction="row" height="100vh" overflow="hidden" position="relative" width="100vw">
            <BlockNavModal />
            <HStack
                align="center"
                bg="white"
                h={topMenuHeight}
                justify="space-between"
                maxH={topMenuHeight}
                minH={topMenuHeight}
                position="absolute"
                px={4}
                width={'100%'}
            >
                <HStack spacing={6}>
                    <VStack spacing={6} w={43}>
                        <VeesualSquare cursor="pointer" onClick={handleLogoClick} w="100%" />
                    </VStack>
                    <HStack zIndex={4}>
                        {
                            (clients && clients.length > 0)
                            && <>
                                <CustomDropdown
                                    getOptionLabel={(client: ClientResponse) => client.name}
                                    getOptionValue={(client: ClientResponse) => client.id}
                                    onChange={handleSelectDomain}
                                    options={clients}
                                    placeholder={t('select_something', { something: t('client') })}
                                    title={t('client')}
                                    value={currentClient}
                                />
                                <CustomDropdown
                                    disabled={!experiences || experiences.length === 0}
                                    getOptionLabel={(experience: Experience) => experience.name}
                                    getOptionValue={(experience: Experience) => experience.id}
                                    onChange={handleSelectExperience}
                                    options={experiences}
                                    title={t('experience')}
                                    value={experiences && experiences.length > 0 ? selectedExperience : { id: '', name: t('default') }}
                                />

                                <CustomDropdown
                                    disabled={!currentClient}
                                    onChange={handleModeChange}
                                    options={PLATFORM_MODE}
                                    title={t('mode')}
                                    value={mode}
                                />
                                <Tabs
                                    index={currentTab}
                                    paddingLeft={8}
                                    paddingRight={8}
                                    variant='header'
                                >
                                    <TabList gridGap={6}>

                                        <Tab
                                            as="a"
                                            href={`/input?${getCleanSearch(search)}`}
                                            onClick={(e) => preventCmdCtrlClick(e, () => handleTabChange(0))}
                                            style={!inputAllowed ? { display: 'none' } : {}}
                                        >
                                            {t('tabs.input')}
                                        </Tab>

                                        <Tab
                                            as="a"
                                            href={`/preprocessing?${getCleanSearch(search)}`}
                                            onClick={(e) => preventCmdCtrlClick(e, () => handleTabChange(1))}
                                            style={!preprocessingAllowed ? { display: 'none' } : {}}
                                        >
                                            {t('tabs.preprocessing')}
                                        </Tab>

                                        <Tab
                                            as="a"
                                            href={`/refwarp?${getCleanSearch(search)}`}
                                            onClick={(e) => preventCmdCtrlClick(e, () => handleTabChange(2))}
                                            style={!refwarpAllowed ? { display: 'none' } : {}}
                                        >
                                            {t('tabs.ref_warp')}
                                        </Tab>
                                        <Tab
                                            as="a"
                                            href={`/crosswarp?${getCleanSearch(search)}`}
                                            onClick={(e) => preventCmdCtrlClick(e, () => handleTabChange(3))}
                                            style={!crosswarpAllowed ? { display: 'none' } : {}}
                                        >
                                            {t('tabs.crosswarp')}
                                        </Tab>
                                        <Tab
                                            as="a"
                                            href={`/external_validation?${getCleanSearch(search)}`}
                                            onClick={(e) => preventCmdCtrlClick(e, () => handleTabChange(4))}
                                            style={isNoRole ? {} : { display: 'none' }}
                                        >
                                            {t('tabs.check_reference')}
                                        </Tab>
                                        <Tab
                                            as="a"
                                            href={`/check?${getCleanSearch(search)}`}
                                            onClick={(e) => preventCmdCtrlClick(e, () => handleTabChange(5))}
                                            style={isAdmin ? {} : { display: 'none' }}
                                        >
                                            {t('tabs.check_final')}
                                        </Tab>
                                    </TabList>
                                </Tabs>
                            </>
                        }
                    </HStack>
                </HStack>
                <HStack zIndex={3}>
                    {
                        isUserSuccess && user
                        && <Menu>
                            <MenuButton _hover={{ bg: 'gray.100' }} borderRadius="lg" px={2} py={1.5}>
                                <HStack>
                                    <Avatar boxSize={9} name={`${user?.firstname} ${user?.lastname}`} />
                                    <VStack align="flex-start" pr={5} spacing={0}>
                                        <Text fontSize="sm" fontWeight="semibold">{`${user?.firstname} ${user?.lastname}`}</Text>
                                    </VStack>
                                    <ChevronDownIcon />
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => navigate('/profile')}>{t('user_menu.profile')}</MenuItem>
                                <MenuItem onClick={clickLogout}>{t('user_menu.logout')}</MenuItem>
                            </MenuList>
                        </Menu>
                    }
                </HStack>
            </HStack>
            <Container
                maxW="100%"
                mt={topMenuHeight}
                overflowY="auto"
                p={0}
                w="full"
            >
                {handleRenderContent()}
            </Container>
        </Flex>
    );
};

export default AuthLayout;
