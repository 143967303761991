import { Box, Center, Image, Link, Text, VStack } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Link as ReachLink, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import AuthLayout from './components/layouts/AuthLayout';
import UnauthLayout from './components/layouts/UnauthLayout';
import usePermission from './components/permission/usePermission';
import { ForgotPassword, Home, Login, Profile } from './pages';
import CheckFinal from './pages/check/CheckFinal';
import CheckFinalValidation from './pages/check/CheckFinalValidation';
import Crosswarp from './pages/crosswarp/Crosswarp';
import CrosswarpCheck from './pages/crosswarp/CrosswarpCheck';
import External from './pages/external/External';
import ExternalValidation from './pages/external/ExternalValidation';
import Feedbacks from './pages/Feedbacks';
import Input from './pages/Input';
import Preprocessing from './pages/preprocessing/Preprocessing';
import PreprocessingQC from './pages/preprocessing/PreprocessingQC';
import RefWarp from './pages/refwarp/RefWarp';
import RefWarpQC from './pages/refwarp/RefWarpQC';
import ResetPassword from './pages/ResetPassword';
import WarpEdit from './pages/warp_edit/WarpEdit';
import { getClient, getUser, isAuthenticated } from './services/store/slices/sessionSlice';
import { useAppSelector } from './services/store/store';
import { Permission } from './types/api-types';
import { localTokenExist, removeTokens } from './utils/auth-helpers';
import { ADMIN, BACKEND, CROSSWARP_STEPS, ERROR_LOCALES, REFWARP_STEPS, SUPERADMIN } from './utils/constants';

const Router: FC = () => {
    const { t } = useTranslation(ERROR_LOCALES);

    function RequireAuth({ children }: { children: ReactElement }) {
        const location = useLocation();

        // We check if local tokens stil exist and the authenticated boolean in the state so we know if the user logged out from another tab
        const isTokenExist = localTokenExist();
        const isAuth = useAppSelector((state) => isAuthenticated(state));

        if (!isTokenExist && !isAuth) {
            // Theres a loop if only the refreshToken exists so we make sure to delete both
            removeTokens();

            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate
                replace
                to={
                    `/login${location.search === '' ? '?' : location.search}${
                        !location.search.match('from=')
                            ? `${location.search !== '' ? '&' : ''}from=${location.pathname}`
                            : ''}`
                }
            />;
        }

        return children;
    }

    function RequireRole({ children }: { children: ReactElement }) {
        const location = useLocation();
        const user = useAppSelector((state) => getUser(state));

        if (!user) {
            return null;
        }

        if (user && user.roles?.length === 0 && user.backend?.length === 0) {
            return <Navigate to={`/external_validation${location.search}`}/>;
        }

        if (!user.roles?.includes(BACKEND) && !user.roles?.includes(ADMIN) && !user.roles?.includes(SUPERADMIN)) {
            return <Navigate to="/external_validation" />;
        }

        return children;
    }

    function RequireClient({ children }: { children: ReactElement }) {
        const currentClient = useAppSelector((state) => getClient(state));

        if (!currentClient) {
            return <Box>{t('select_client')}</Box>;
        }

        return children;
    }

    function RequirePermission(props: { permission: Permission, children: ReactElement }) {
        const { permission, children } = props;
        const adminroutesAllowed = usePermission(permission);

        if (!adminroutesAllowed) {
            return <Center h="100%">{t('not_allowed')}</Center>;
        }

        return children;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route element={
                    <RequireAuth>
                        <AuthLayout />
                    </RequireAuth>
                } path="/">
                    <Route element={<Profile />} path="profile" />
                    {/* Need client to go to those routes */}
                    <Route element={
                        <RequireClient>
                            <Outlet />
                        </RequireClient>
                    }>
                        <Route element={<External />} path="external_validation" />
                        <Route element={<ExternalValidation />} path="external_validation/:warpId" />
                        <Route element={
                            <RequireRole>
                                <Outlet />
                            </RequireRole>
                        }>
                            <Route element={<Home />} path="/" />
                            <Route element={
                                <RequirePermission permission="input">
                                    <Outlet />
                                </RequirePermission>
                            }>
                                <Route element={<Input />} path="input" />
                            </Route>
                            <Route element={
                                <RequirePermission permission="preprocessing">
                                    <Outlet />
                                </RequirePermission>
                            }>
                                <Route element={<Preprocessing />} path="preprocessing" />
                                <Route element={<PreprocessingQC />} path="preprocessing/:garmentId/quality" />
                            </Route>

                            <Route element={<RefWarp />} path="refwarp" />
                            <Route element={<RefWarpQC initialSteps={REFWARP_STEPS} key="refwarp" />} path="refwarp/:warpId/quality" />
                            <Route element={<Crosswarp />} path="crosswarp" />
                            <Route element={<CrosswarpCheck />} path="crosswarp/:warpId" />
                            <Route element={<RefWarpQC initialSteps={CROSSWARP_STEPS} key="crosswarp" />} path="crosswarp/:warpId/quality" />
                            <Route element={<WarpEdit />} path="warp/:warpId/edit" />
                            <Route element={<WarpEdit />} path="garment/:garmentId/warp" />
                            <Route element={<CheckFinal />} path="check" />
                            <Route element={<CheckFinalValidation />} path="check/:garmentId" />
                        </Route>
                        <Route element={<Feedbacks />} path="warp/:warpId/feedbacks" />
                        <Route element={<Feedbacks />} path="garment/:garmentId/feedbacks" />
                        <Route element={<Feedbacks />} path="external_validation/:warpId/feedbacks" />
                        <Route element={<Feedbacks />} path="check/:garmentId/feedbacks" />
                    </Route>
                    <Route
                        element={
                            <VStack pb={6}>
                                <Image boxSize={96} src="/assets/illustrations/not-found.svg" />
                                <Text>{t('404.message')}</Text>
                                <Link as={ReachLink} fontSize="lg" to="/">{t('404.back_to_home')}</Link>
                            </VStack>
                        }
                        path="*"
                    />
                </Route>
                <Route element={<UnauthLayout />} path="/">
                    <Route element={<Login />} path="login" />
                    <Route element={<ForgotPassword />} path="forgot-password" />
                    <Route element={<ResetPassword />} path="reset-password/:token" />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
