import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    Button,
    HStack,
    IconButton,
    List,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    Textarea,
    useToast,
    VStack,
} from '@chakra-ui/react';
import html2canvas from 'html2canvas';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { Feedback, FeedbackImage, FeedbackImageObject, FeedbackParams, PendingFeedback } from '../../types/api-types';
import { COMMON_LOCALES, ERROR_LOCALES, PREPROCESSING_LOCALES, SUB_HEADER_HEIGHT } from '../../utils/constants';
import useRoles from '../../utils/roles-hook';
import CustomCanvasDraw from '../CustomCanvasDraw';
import FeedbackListItem from '../FeedbackListItem';
import ImageWithError from '../ImageWithError';

const headerHeight = `${SUB_HEADER_HEIGHT}px`;

interface FeedbackModalProps {
    onClose: () => void;
    modalOpen: boolean;
    stepDetail?: string;
    garmentName?: string;
    hidePriority?: boolean;
    onSend?: (params: FeedbackParams) => Promise<void>;
    initialInput?: string;
    screenId?: string;
    sendLabel?: string;
    images?: FeedbackImage[];
    history?: Feedback[];
    pendingFeedbacks?: PendingFeedback[];
    changePendingFeedbacks?(newPendingFeedbacks: PendingFeedback[]): void;
}

export default function FeedbackModal(props: FeedbackModalProps) {
    const { t } = useTranslation([PREPROCESSING_LOCALES, ERROR_LOCALES, COMMON_LOCALES]);
    const toast = useToast();
    const { pathname } = useLocation();
    const { isAdmin } = useRoles();

    const {
        onClose,
        modalOpen,
        stepDetail,
        onSend,
        garmentName,
        initialInput,
        hidePriority,
        sendLabel,
        screenId,
        images,
        history,
        pendingFeedbacks,
        changePendingFeedbacks,
    } = props;

    const [inputValue, setInputValue] = useState<string | undefined>(initialInput);
    const [isInputInvalid, setIsInputInvalid] = useState<boolean>(false);

    const [priority, setPriority] = useState<number>();
    const [isPriorityInvalid, setIsPriorityInvalid] = useState<boolean>(false);

    const [screenshotUrl, setScreenshotUrl] = useState<string>();
    const [screenElem, setScreenElem] = useState<HTMLElement | null>(document.getElementById(screenId || 'qcContainerId'));

    const [isDrawError, setIsDrawError] = useState<boolean>(false);

    const [activeImages, setActiveImages] = useState<number[]>([]);

    const customCanvasRef = useRef<{ getAllImageObjects:() => Promise<(FeedbackImageObject | null)[]>; } | null>(null);

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        // ---- If we were invalid and the new value is valid we reset the Invalid flag ----
        if (isInputInvalid && event.target.value !== '') {
            setIsInputInvalid(false);
        }

        // ---- If the value is empty we set the invalid flag ----
        if (event.target.value === '') {
            setIsInputInvalid(true);
        }

        // ---- We assing the value regardless of the validity ----
        setInputValue(event.target.value);
    };

    const resetInvalidValues = () => {
        setIsInputInvalid(false);
        setIsPriorityInvalid(false);
        setIsDrawError(false);
    };

    const handleModalClose = () => {
        // ---- Reset the state values ----
        setInputValue('');
        setPriority(undefined);

        // ---- Invalid Flags reset ----
        resetInvalidValues();

        // ---- Remove activeImages ----
        setActiveImages([]);

        // ---- onClose callback ----
        onClose();
    };

    const handleSendClick = () => {
        // ---- Local Invalid flag ----
        let isAnyInvalid = false;

        // ---- We don't allow to send an empty feedback ----
        if (!inputValue) {
            setIsInputInvalid(true);
            isAnyInvalid = true;
            toast({
                isClosable: true,
                status: 'error',
                title: t('feedback.input', { ns: ERROR_LOCALES }),
            });
        }

        // ---- We don't allow to send without Priority (if we did not hide the priority) ----
        if (!priority && !hidePriority) {
            setIsPriorityInvalid(true);
            isAnyInvalid = true;
            toast({
                isClosable: true,
                status: 'error',
                title: t('feedback.priority', { ns: ERROR_LOCALES }),
            });
        }

        // ---- Get the image objects to check if we've drawn and handle the sending ----
        customCanvasRef.current?.getAllImageObjects().then((imageObjects) => {
            // ---- We filter the array to remove the null (it means we did not draw) ----
            const filteredObjects = imageObjects.filter((obj) => obj !== null) as FeedbackImageObject[];

            // ---- We don't allow to send without drawing ----
            if (!filteredObjects || filteredObjects.length === 0 || filteredObjects.filter((imgObj) => imgObj.drawing).length === 0) {
                isAnyInvalid = true;
                setIsDrawError(true);
                toast({
                    isClosable: true,
                    status: 'error',
                    title: t('feedback.annotate', { ns: ERROR_LOCALES }),
                });
            }

            // ---- We stop the sending process ----
            if (isAnyInvalid) {
                return;
            }

            // ---- Reset the invalid values ----
            resetInvalidValues();

            if (onSend && inputValue) {
                // ---- We don't close the modal directly here ----
                onSend({
                    images: filteredObjects.map((obj) => ({ annotation: obj.drawing, image: obj.image, superpose: obj.superpose })),
                    message: inputValue,
                    priority,
                }).catch((err) => {
                    console.error(err);
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const capture = () => {
        if (!screenElem || !modalOpen) {
            return;
        }

        html2canvas(screenElem, { scale: 2, useCORS: true })
            .then((canvas1) => {
                setScreenshotUrl(canvas1.toDataURL('image/jpeg'));
            })
            .catch((err: Error) => {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: err?.message,
                });
            });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // ---- If we were invalid and the new value is valid we reset the Invalid flag ----
        if (isPriorityInvalid && e.target.value !== '') {
            setIsPriorityInvalid(false);
        }

        // ---- If the value is empty we set the invalid flag and reset the Priority value ----
        if (e.target.value === '') {
            setPriority(undefined);
            setIsPriorityInvalid(true);

            return;
        }

        // ---- If the value is valid we parse it and set the Priority ----
        setPriority(parseInt(e.target.value, 10));
    };

    const handleRemovePending = (index: number) => {
        if (changePendingFeedbacks && pendingFeedbacks) {
            changePendingFeedbacks(pendingFeedbacks.filter((fb, i) => i !== index));
        }
    };

    useEffect(() => {
        capture();
    }, [screenElem]);

    // ---- Needed to update the default input value ----
    useEffect(() => {
        if (modalOpen === true) {
            setInputValue(initialInput || '');
            capture();
        }
        setScreenElem(document.getElementById(screenId || 'qcContainerId'));
    }, [modalOpen, screenId]);

    // ---- Add the 2 first images from array as active ----
    useEffect(() => {
        if (images && activeImages.length === 0 && modalOpen) {
            setActiveImages([0, 1]);
        }
    }, [images, modalOpen]);

    const PRIORITY_OPTIONS = useMemo(() => {
        let fullPrio = [
            { label: t('feedback.priority.1', { ns: COMMON_LOCALES }), value: 1 },
            { label: t('feedback.priority.7', { ns: COMMON_LOCALES }), value: 7 },
            { label: t('feedback.priority.9', { ns: COMMON_LOCALES }), value: 9 },
        ];

        // ---- We remove the priority 7 if we are in preprocessing ----
        if (matchPath('/preprocessing/:garmentId/quality', pathname)) {
            fullPrio = fullPrio.filter((prio) => prio.value !== 7);
        }

        // ---- We remove the priority 9 if we are not an admin ----
        if (!isAdmin) {
            fullPrio = fullPrio.filter((prio) => prio.value !== 9);
        }

        return fullPrio;
    }, [pathname, isAdmin]);

    return (
        <Modal autoFocus={false} isOpen={modalOpen} motionPreset='slideInBottom' onClose={handleModalClose} variant="feedback">
            <ModalOverlay bg="blackAlpha.300" />

            <ModalContent>
                <ModalHeader textAlign={'center'}>
                    {t('feedback')}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody style={{ borderTop: '1px solid lightgray' }}>
                    <HStack h="100%" spacing={0}>
                        <VStack h="100%" w={`calc(${100 - ((history && history.length !== 0) ? 20 : 0)}%)`}>
                            <HStack h={headerHeight} mt={2} pl={4} pr={4} w="100%">
                                <VStack alignItems={'flex-start'} flex={1} spacing={6}>
                                    {garmentName && <Text>{t('garment', { ns: COMMON_LOCALES })}: {garmentName}</Text>}
                                    {stepDetail && <Text>{t('step')}: {stepDetail}</Text>}
                                </VStack>
                                <HStack flex={5}>
                                    <Textarea
                                        flex={3}
                                        isInvalid={isInputInvalid}
                                        justifyContent="center"
                                        minHeight="80px"
                                        onChange={handleInputChange}
                                        placeholder={t('feedback.placeholder', { ns: COMMON_LOCALES })}
                                        value={inputValue}
                                    />
                                    <VStack flex={1}>
                                        {
                                            !hidePriority && <VStack alignItems="flex-start" spacing={0}>
                                                <Text fontSize={12} fontWeight="bold">{t('feedback.priority.title', { ns: COMMON_LOCALES })}</Text>
                                                <Select
                                                    border={isPriorityInvalid ? '1px solid red' : ''}
                                                    isInvalid={isPriorityInvalid}
                                                    onChange={handleSelectChange}
                                                    placeholder={t('feedback.placeholder_select', { ns: COMMON_LOCALES })}
                                                    value={priority}
                                                    variant="unstyled"
                                                >
                                                    {
                                                        PRIORITY_OPTIONS.map((option) => (
                                                            <option key={option.value.toString()} value={option.value}>{option.label}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </VStack>
                                        }
                                        <HStack flex={1} justifyContent="center">
                                            <Button minW="100px" onClick={handleModalClose} variant="ghost">
                                                {t('close', { ns: COMMON_LOCALES })}</Button>
                                            <Button minW="100px" onClick={handleSendClick} >{sendLabel || t('send', { ns: COMMON_LOCALES })}</Button>
                                        </HStack>
                                    </VStack>

                                </HStack>
                            </HStack>
                            <HStack h={`calc(100% - ${SUB_HEADER_HEIGHT + 16}px)`} w="100%">
                                {
                                    !images
                                        ? <CustomCanvasDraw
                                            imageSrc={screenshotUrl}
                                            isError={isDrawError}
                                            ref={customCanvasRef}
                                        />
                                        : <>
                                            <HStack h="100%" overflowX="auto" w="100%">
                                                <CustomCanvasDraw
                                                    imageSrc={images.filter((_, index) => activeImages.includes(index))}
                                                    isError={isDrawError}
                                                    ref={customCanvasRef}
                                                />
                                            </HStack>
                                            <VStack h="100%" w="10%">
                                                <Text>{t('images', { ns: COMMON_LOCALES })}</Text>
                                                <VStack h="100%" overflowY="auto" p={4} spacing={4}>
                                                    {
                                                        images.map((img, index) => (
                                                            <VStack
                                                                borderRadius="lg"
                                                                cursor="pointer"
                                                                key={index}
                                                                onClick={() => {
                                                                    if (activeImages.includes(index)) {
                                                                        setActiveImages(
                                                                            activeImages.filter(
                                                                                (activeImageIndex) => activeImageIndex !== index,
                                                                            ),
                                                                        );

                                                                        return;
                                                                    }

                                                                    setActiveImages([...activeImages, index]);
                                                                }}
                                                                outline={
                                                                    activeImages.includes(index)
                                                                        ? '2px solid var(--chakra-colors-primary-500)'
                                                                        : 'none'
                                                                }
                                                                position="relative"
                                                            >
                                                                {
                                                                    activeImages.includes(index) && <IconButton
                                                                        aria-label='remove-feedback-image'
                                                                        backgroundColor="white"
                                                                        icon={<CloseIcon />}
                                                                        onClick={() => {
                                                                            setActiveImages(
                                                                                activeImages.filter((activeImageIndex) => activeImageIndex !== index),
                                                                            );
                                                                        }}
                                                                        position="absolute"
                                                                        right={-2}
                                                                        size="sm"
                                                                        top={-2}
                                                                        variant="outline"
                                                                        zIndex={1}
                                                                    />
                                                                }
                                                                {
                                                                    typeof img.src === 'string'
                                                                        ? <ImageWithError
                                                                            src={img.src}
                                                                        />
                                                                        : <>
                                                                            {
                                                                                img.src.map((src, i) => <ImageWithError
                                                                                    key={src}
                                                                                    // ---- The first image defines the size of -----
                                                                                    // ---- the container so it's not in absolute -----
                                                                                    position={i === 0 ? undefined : 'absolute'}
                                                                                    src={src}
                                                                                />)
                                                                            }
                                                                        </>
                                                                }
                                                                {
                                                                    img.superpose && <>
                                                                        {
                                                                            typeof img.superpose === 'string'
                                                                                ? <ImageWithError
                                                                                    key={img.superpose}
                                                                                    opacity={img.opacityValue}
                                                                                    position="absolute"
                                                                                    src={img.superpose}
                                                                                />
                                                                                : <>
                                                                                    {
                                                                                        img.superpose.map((src) => <ImageWithError
                                                                                            key={src}
                                                                                            opacity={img.opacityValue}
                                                                                            position="absolute"
                                                                                            src={src}
                                                                                        />)
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                }

                                                            </VStack>
                                                        ))
                                                    }
                                                </VStack>
                                            </VStack>

                                        </>
                                }
                            </HStack>
                        </VStack>
                        {
                            ((history && history.length !== 0) || (pendingFeedbacks && pendingFeedbacks.length !== 0))
                            && <VStack alignItems="flex-start" borderLeft='1px solid lightgray' h="100%" p={2} spacing={4} w="20%">
                                {
                                    pendingFeedbacks && pendingFeedbacks.length !== 0 && <>
                                        <Text fontSize="large" fontWeight='bold' textAlign="center" w="100%">
                                            {t('feedback.feedbacks_pending', { ns: COMMON_LOCALES })}
                                        </Text>
                                        <List w="100%">
                                            {
                                                pendingFeedbacks.map(
                                                    (feedback, i) => (<HStack
                                                        alignItems="flex-start"
                                                        borderRadius="lg"
                                                        justifyContent={'space-between'}
                                                        key={feedback.message}
                                                        p={2}
                                                        spacing={0}
                                                        textAlign="left"
                                                        w="100%"
                                                    >
                                                        <VStack alignItems="flex-start" spacing={0}>
                                                            <Text fontWeight="bold">{t(`qc_steps.${feedback.step}`, { ns: COMMON_LOCALES })} :</Text>
                                                            <Text>{feedback.message}</Text>
                                                        </VStack>
                                                        <IconButton
                                                            aria-label='remove-pending-feedback'
                                                            icon={<DeleteIcon/>}
                                                            onClick={() => handleRemovePending(i)}
                                                            variant="ghost"
                                                        />
                                                    </HStack>),
                                                )
                                            }
                                        </List>
                                    </>
                                }

                                {
                                    history && history.length !== 0 && <>
                                        <Text fontSize="large" fontWeight='bold' textAlign="center" w="100%">
                                            {t('feedbacks_history', { ns: COMMON_LOCALES })}
                                        </Text>
                                        <List>
                                            {
                                                history && history.map((feedback) => <FeedbackListItem feedback={feedback} key={feedback.id} />)
                                            }
                                        </List>
                                    </>
                                }

                            </VStack>
                        }
                    </HStack>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
