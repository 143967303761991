import { useMemo } from 'react';

import { getUser } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { Permission, Permissions } from '../../types/api-types';
import {
    ADMIN, ADMIN_PERMISSION, BACKEND, BACKEND_QUALITY_PERMISSION,
    BACKEND_RWINTQC_PERMISSION,
    BACKEND_WARP_PERMISSION, SUPERADMIN, SUPERADMIN_PERMISSION,
} from '../../utils/constants';

/**
 * Safely evaluates a dynamic permission value.
 * @param permissions - The permissions object.
 * @param key - The permission key to check.
 * @param status - The status passed to dynamicValue.
 * @returns boolean
 */
function evaluateDynamicPermission(
    permissions: Permissions,
    key: Permission,
    status?: string,
): boolean {
    const permission = permissions[key];

    // ---- Check if permission, dynamicValue and the status exist ----
    if (permission?.dynamicValue && status) {
        return permission.dynamicValue(status);
    }

    // ---- Default fallback if dynamicValue does not exist ----
    return permission?.allowed ?? false;
}

function isAllowedTo(newPermission: Permission, roles?: string[], backends?: string[], status?: string): boolean {
    let isAllowed = false;

    if (!roles || roles.length === 0) {
        return false;
    }

    roles.forEach((role) => {
        if (!isAllowed) {
            switch (role) {
                case SUPERADMIN:
                    isAllowed = evaluateDynamicPermission(SUPERADMIN_PERMISSION, newPermission, status);
                    break;
                case ADMIN:
                    isAllowed = evaluateDynamicPermission(ADMIN_PERMISSION, newPermission, status);
                    break;
                case BACKEND:
                    if (backends) {
                        if (backends.includes('WARP')) {
                            isAllowed = isAllowed || evaluateDynamicPermission(BACKEND_WARP_PERMISSION, newPermission, status);
                        }
                        if (backends.includes('QUALITY')) {
                            isAllowed = isAllowed || evaluateDynamicPermission(BACKEND_QUALITY_PERMISSION, newPermission, status);
                        }
                        if (backends.includes('RWINTQC')) {
                            isAllowed = isAllowed || evaluateDynamicPermission(BACKEND_RWINTQC_PERMISSION, newPermission, status);
                        }
                    }
                    break;
            }
        }
    });

    return isAllowed;
}

const usePermission = (permission: Permission, status?: string) => {
    const roles = useAppSelector((state) => getUser(state)?.roles);
    const backends = useAppSelector((state) => getUser(state)?.backend);

    // Memoize the result to prevent unnecessary recalculations
    return useMemo(() => isAllowedTo(permission, roles, backends, status), [permission, roles, backends, status]);
};

export default usePermission;
