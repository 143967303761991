import { createApi } from '@reduxjs/toolkit/query/react';

import { CoherenceKpReponse, JkpResponse, KeypointConversionPayload, WarpPayload } from '../../types/api-types';
import { baseWarpEditQuery } from './queries';

const getPrefix = (input: KeypointConversionPayload | WarpPayload, urlPrefix?: string) => {
    // ---- If no smart and we have a prefix from config we use it ----
    if (!(input as WarpPayload)?.smart && urlPrefix) {
        return `${urlPrefix}/`;
    }

    return '';
};

export const apiWarpEdit = createApi({
    baseQuery: baseWarpEditQuery,
    endpoints: (builder) => ({
        postCoherenceKp: builder.mutation<
            CoherenceKpReponse,
            { payload: WarpPayload, urlPrefix?: string }
        >({
            query: (input) => ({
                body: input.payload,
                method: 'POST',
                url: `${getPrefix(input.payload, input.urlPrefix)}warp/coherence/kp_pairs`,
            }),
        }),
        postD2S: builder.mutation<
            number[] | number[][],
            { payload: KeypointConversionPayload, urlPrefix?: string }
        >({
            query: (input) => ({
                body: input.payload,
                method: 'POST',
                url: `${getPrefix(input.payload, input.urlPrefix)}warp/coord/d2s`,
            }),
        }),
        postJKP: builder.mutation<
            JkpResponse,
            WarpPayload
        >({
            query: (input) => ({
                body: input,
                method: 'POST',
                url: `${getPrefix(input, input.urlPrefix)}warp/coord/jkp_pairs`,
            }),
        }),
        postS2D: builder.mutation<
            number[] | number[][],
            { payload: KeypointConversionPayload, urlPrefix?: string }
        >({
            query: (input) => ({
                body: input.payload,
                method: 'POST',
                url: `${getPrefix(input.payload, input.urlPrefix)}warp/coord/s2d`,
            }),
        }),
        postWarpEdit: builder.mutation<
            string | WarpPayload,
            { payload: WarpPayload, urlPrefix?: string }
        >({
            query: (input) => ({
                body: input.payload,
                method: 'POST',
                responseHandler: (resp) => new Promise((resolve) => {
                    if (!input.payload.only_coords && resp.ok) {
                    // ---- Because response content-type is image/png we convert the blob ----
                        resp.blob().then((blob) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.readAsDataURL(blob);
                        }).catch((err) => {
                            throw new Error(err);
                        });
                    } else {
                        resolve(resp.json());
                    }
                }),
                url: `${getPrefix(input.payload, input.urlPrefix)}warp`,
            }),
        }),
    }),
    reducerPath: 'apiWarpEdit',
});

export const {
    usePostWarpEditMutation,
    usePostD2SMutation,
    usePostS2DMutation,
    usePostJKPMutation,
    usePostCoherenceKpMutation,
} = apiWarpEdit;
