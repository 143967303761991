import { Box, Button, Divider, Flex, HStack, Text, useToast, VStack, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { forceCheck } from 'react-lazyload';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CrosswarpCheckCard from '../../components/cards/CrosswarpCheckCard';
import CrossWarpsContext, { ExistingCrossWarp, isCrossWarpsEqual, NewCrossWarp } from '../../components/crosswarps/CrossWarpsContext';
import CrossWarpCheckSubHeader from '../../components/headers/CrossWarpCheckSubHeader';
import FeedbackModal from '../../components/modals/FeedbackModal';
import ValidateModal from '../../components/modals/ValidateModal';
import { useGetGarmentDetailQuery } from '../../services/api/api-garment';
import {
    useGetCrossWarpCheckQuery,
    useGetReservationMutation,
    useGetWarpQualityQuery,
    usePostValidateCrossWarpsMutation,
} from '../../services/api/api-warp';
import { addReservation, getClient, resetReservation } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { CrosswarpCheckType, FeedbackParams, Reservation, ValidateCrossWarpsReponse } from '../../types/api-types';
import { COMMON_LOCALES, CROSSWARP_IMAGESIZE_KEY, CROSSWARP_LOCALES, ERROR_LOCALES, PREFILTERS } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import getApiErrorMessage from '../../utils/error-helpers';
import useFeedbacks from '../../utils/feedback-hook';
import resizeImage from '../../utils/image';
import { loadFromLocalStorage } from '../../utils/local-storage-helpers';
import handlePaginationScroll from '../../utils/scroll-pagination-helpers';
import isSelectableFromStatus from '../../utils/status-helpers';
import WarpDetailGridElement from '../preprocessing/gridElements/WarpDetailGridElement';

const SCREEN_ID = 'crosswarpFeedback';

export default function CrosswarpCheck() {
    const { t } = useTranslation([COMMON_LOCALES, ERROR_LOCALES, CROSSWARP_LOCALES]);
    const toast = useToast();
    const { warpId } = useParams();
    const navigate = useCustomNavigate();
    const dispatch = useDispatch();
    const { sendCrossWarpFeedback, sendWarpFeedback } = useFeedbacks();

    const currentClient = useAppSelector((state) => getClient(state));
    const reservedList = useAppSelector((state) => state.session.reservations);

    const [gridScrollPage, setGridScrollPage] = useState<number>(1);
    const [productsPerRow, setProductsPerRow] = useState<number>(loadFromLocalStorage(CROSSWARP_IMAGESIZE_KEY) || 4);

    const { data: warpDetail, error: errorWarpDetail } = useGetWarpQualityQuery(
        { clientId: currentClient?.id || '', id: warpId || '' },
        // refetchOnMountOrArgChange is needed if we come back after having validated the garment
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    );

    const { data: crosswarpData, error: errorWarpSampleModels, isFetching: isCrosswarpLoading } = useGetCrossWarpCheckQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: gridScrollPage },
        // refetchOnMountOrArgChange is needed if we come back after having validated the garment
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    );

    const { data: garmentDetail, error: errorGarmentDetail } = useGetGarmentDetailQuery(
        { clientId: currentClient?.id || '', id: warpDetail?.garment_id || '' },
        { skip: !currentClient || !warpDetail },
    );

    const [postValidateSelected] = usePostValidateCrossWarpsMutation();

    const [fullCrosswarps, setFullCrosswarps] = useState<CrosswarpCheckType[]>([]);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);
    const [selectedCrosswarp, setSelectedCrosswarp] = useState<CrosswarpCheckType>();
    const [garmentOpacity, setGarmentOpacity] = useState<number>(1);

    // ---- CrossWarpsContext var and functions ----
    const [selectedCrossWarps, setSelectedCrossWarps] = useState<(ExistingCrossWarp | NewCrossWarp)[]>([]);
    const selectACrossWarp = (crosswarp: (ExistingCrossWarp | NewCrossWarp)) => setSelectedCrossWarps([...selectedCrossWarps, crosswarp]);
    const changeCheckAll = (newVal: boolean, fromDeselect = false) => {
        if (!newVal && !fromDeselect) {
            setSelectedCrossWarps([]);
        } else {
            // ---- We set all the crosswarps that have the TODO status in the selected array ----
            setSelectedCrossWarps(fullCrosswarps.filter((localCrosswarps) => isSelectableFromStatus(localCrosswarps.warp_status || '')));
        }
    };
    const deselectACrossWarp = (crosswarp: (ExistingCrossWarp | NewCrossWarp)) => setSelectedCrossWarps(
        selectedCrossWarps.filter((localCrosswarp) => !isCrossWarpsEqual(localCrosswarp, crosswarp)),
    );

    const handleImageSizeChange = (newValue: number) => {
        setProductsPerRow(newValue);
        forceCheck();
    };

    const handlePostValidateSelectedSuccess = (response: ValidateCrossWarpsReponse, status?: string) => {
        if (response.success && response.warps) {
            toast({
                isClosable: true,
                status: 'success',
                title: t(`validate.toast_success${status ? `_${status}` : ''}`, { ns: CROSSWARP_LOCALES }),
            });

            // ---- We update the local data with the response data (for status synchro) ----
            setFullCrosswarps(fullCrosswarps.map(
                (localCrossWarp) => {
                    let result = localCrossWarp;

                    // ---- We check if the localCrossWarp is equals to any of our selected one ----
                    response?.warps?.forEach((respWarp, index) => {
                        if (isCrossWarpsEqual(localCrossWarp, selectedCrossWarps[index])) {
                            // ---- We assign the new status ----
                            result = {
                                ...localCrossWarp,
                                warp_id: respWarp.warp_id,
                                warp_status: respWarp.warp_status,
                            };
                        }
                    });

                    return result;
                },
            ));

            // ---- Reset the selected items because they are not selectable afterwards ----
            setSelectedCrossWarps([]);
        } else if (response.errors) {
            response.errors.forEach((error) => {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: error,
                });
            });
        }
    };

    const handleFeedbackSuccess = () => {
        setModalOpen(false);

        // ---- We update the status value of the local crosswarp ----
        setFullCrosswarps(fullCrosswarps.map((localCrossWarp) => {
            if (localCrossWarp === selectedCrosswarp) {
                return {
                    ...localCrossWarp,
                    warp_status: PREFILTERS.HASFEEDBACK,
                };
            }

            return localCrossWarp;
        }));

        setSelectedCrosswarp(undefined);
    };

    const sendFeedback = (params: FeedbackParams) => new Promise((resolve: (returnValue: void) => void) => {
        if (currentClient && warpDetail) {
            // ---- If no Selected Crosswarp it means we are sending a Ref Warp Feedback ----
            if (!selectedCrosswarp) {
                resolve();

                return sendWarpFeedback(
                    { ...params, warpId: warpDetail.warp_id },
                    () => {
                        handleFeedbackSuccess();
                        navigate('/crosswarp');
                    },
                );
            }

            // ---- If we have a warp_id we use it to post the feedback ----
            if (selectedCrosswarp.warp_id) {
                resolve();

                return sendWarpFeedback(
                    { ...params, warpId: selectedCrosswarp.warp_id },
                    handleFeedbackSuccess,
                );
            }

            resolve();

            // ---- If we don't have a warp id yet we use the specific post function with garment_id and model_id ----
            return sendCrossWarpFeedback(
                { ...params, garmentId: selectedCrosswarp.garment_id, modelId: selectedCrosswarp.model_id },
                handleFeedbackSuccess,
            );
        }

        resolve();

        return null;
    });

    const handleMultipleValidate = () => {
        if (selectedCrossWarps.length > 0) {
            setValidateModalOpen(true);
        }
    };

    const handleModalValidate = (skip?: boolean) => {
        if (!currentClient) {
            return;
        }

        const status = skip ? PREFILTERS.READY : PREFILTERS.TOHDQC;
        postValidateSelected({
            clientId: currentClient.id,
            ids: selectedCrossWarps,
            status,
        }).unwrap().then((response) => {
            handlePostValidateSelectedSuccess(response, status);

            setValidateModalOpen(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const handleSendToQC = () => {
        if (!currentClient) {
            return;
        }
        postValidateSelected({
            clientId: currentClient.id,
            ids: selectedCrossWarps,
            status: PREFILTERS.TOQUALITY,
        }).unwrap().then((response) => {
            handlePostValidateSelectedSuccess(response, PREFILTERS.TOQUALITY);
        }).catch((err) => {
            console.error(err);
        });
    };

    // ---- Called when the grid of sample is scrolled ----
    const handleScrollChangePage = () => {
        handlePaginationScroll(isCrosswarpLoading, gridScrollPage, () => setGridScrollPage(gridScrollPage + 1), crosswarpData, fullCrosswarps.length);
    };

    useEffect(() => {
        if (!modalOpen) {
            setSelectedCrosswarp(undefined);
        }
    }, [modalOpen]);

    useEffect(() => {
        if (!crosswarpData) {
            return undefined;
        }

        const apiCurrentPage = parseInt(crosswarpData.current_page_number, 10);

        // ---- We concat the data to have the full list if we incremented the page ----
        if (gridScrollPage === apiCurrentPage && gridScrollPage !== 1) {
            setFullCrosswarps((prev) => prev.concat(crosswarpData ? crosswarpData.items : []));

            return undefined;
        }

        setFullCrosswarps(crosswarpData.items);

        return undefined;
    }, [crosswarpData]);

    useEffect(() => {
        if (errorWarpDetail || errorWarpSampleModels || errorGarmentDetail) {
            navigate('/crosswarp');
        }
    }, [errorWarpDetail || errorWarpSampleModels || errorGarmentDetail]);

    // ---- Reserved query -----
    const [getReserved] = useGetReservationMutation();
    useEffect(() => {
        const reservedCall = (reservedResp: { data: Reservation[] }) => {
            const castedResp = reservedResp;
            if (castedResp && castedResp.data) {
                dispatch(addReservation(castedResp.data));
            }
        };
        const interval = window.setInterval(() => {
            getReserved().then((reservedResp) => {
                reservedCall(reservedResp as { data: Reservation[] });
            }).catch((err) => {
                console.error(err);
            });
        }, 10000);

        getReserved().then((reservedResp) => {
            reservedCall(reservedResp as { data: Reservation[] });
        }).catch((err) => {
            console.error(err);
        });

        return () => {
            dispatch(resetReservation());
            clearInterval(interval);
        };
    }, []);

    return (
        <Box height='100%' id='crosswarpFeedback'>
            <ValidateModal
                modalOpen={validateModalOpen}
                onClose={() => setValidateModalOpen(false)}
                onValidate={() => handleModalValidate(true)}
            >
                <Button
                    onClick={() => handleModalValidate()}
                    variant='outline'
                >
                    {t('validate_modal.send_to_hd', { ns: COMMON_LOCALES })}
                </Button>
            </ValidateModal>
            <VStack boxSize="full" spacing={0}>
                <CrossWarpsContext.Provider
                    value={{
                        changeCheckAll,
                        deselectACrossWarp,
                        selectACrossWarp,
                        selectedCrossWarps,
                    }}
                >
                    <CrossWarpCheckSubHeader
                        onFeedbackClick={() => {
                            setModalOpen(true);
                        }}
                        onImageSizeChange={handleImageSizeChange}
                        onOpacityChange={setGarmentOpacity}
                        onSendToQC={handleSendToQC}
                        onValidate={handleMultipleValidate}
                        warpDetail={warpDetail}
                        warpId={warpId}
                    />
                    {
                        (errorWarpDetail || errorWarpSampleModels || errorGarmentDetail)
                            ? <VStack h="100%" justifyContent="center">
                                {errorWarpDetail && <Text>{getApiErrorMessage(errorWarpDetail)}</Text>}
                                {errorWarpSampleModels && <Text>{getApiErrorMessage(errorWarpSampleModels)}</Text>}
                                {errorGarmentDetail && <Text>{getApiErrorMessage(errorGarmentDetail)}</Text>}
                            </VStack>
                            : <HStack boxSize="full" overflow="hidden" pl={4} spacing={0}>
                                <FeedbackModal
                                    modalOpen={modalOpen}
                                    onClose={() => { setModalOpen(false); }}
                                    onSend={sendFeedback}
                                    screenId={SCREEN_ID}
                                />
                                <VStack flex={1} h="100%" minH="100%" spacing={0}>
                                    {
                                        (warpDetail && garmentDetail)
                                    && <WarpDetailGridElement
                                        garmentDetail={{
                                            description: garmentDetail.product_description,
                                            imageLabel: garmentDetail.garment_name,
                                            productCategory: garmentDetail.garment_type,
                                            productName: garmentDetail.product_name,
                                        }}
                                        imageSrc={resizeImage(warpDetail.image_url, { width: 800 })}
                                        label={t('ref_warp', { ns: CROSSWARP_LOCALES })}
                                        modelIdentity={warpDetail.model_identity}
                                        warpDetail={warpDetail}
                                    />
                                    }
                                </VStack>

                                <Divider orientation='vertical' />
                                <VStack alignItems="flex-end" flex={3} h="100%" pl={4} spacing={0}>

                                    <Flex
                                        as="ul"
                                        flexWrap="wrap"
                                        gridGap={4}
                                        height="100%"
                                        onScroll={handleScrollChangePage}
                                        overflowY="auto"
                                        pt={2}
                                        width="100%"
                                    >
                                        {
                                            fullCrosswarps.map((crosswarp) => (
                                                <WrapItem
                                                    key={crosswarp.garment_id + crosswarp.model_id}
                                                    w={`calc(100% / ${productsPerRow} - 16px)`}
                                                >
                                                    <CrosswarpCheckCard
                                                        crosswarp={crosswarp}
                                                        imageRatio='100%'
                                                        isReserved={!!reservedList.find((reservation) => (
                                                            reservation.garment_id === crosswarp.garment_id
                                                        && reservation.model_id === crosswarp.model_id
                                                        ))}
                                                        onFeedbackClick={() => {
                                                            setModalOpen(true);
                                                            setSelectedCrosswarp(crosswarp);
                                                        }}
                                                        opacity={garmentOpacity}
                                                    />
                                                </WrapItem>

                                            ))
                                        }
                                    </Flex>

                                </VStack>
                            </HStack>
                    }
                </CrossWarpsContext.Provider>
            </VStack>

        </Box >

    );
}
