import {
    Box,
    Button,
    Center,
    Divider,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Spinner,
    Switch,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UndoIcon } from '../../assets/icons';
import DropdownButton from '../../components/buttons/DropdownButton';
import FeedbackCardsList from '../../components/FeedbackCardsList';
import BaseSubHeader from '../../components/headers/BaseSubHeader';
import HasFeedbackBanner from '../../components/headers/HasFeedbackBanner';
import ReservedBanner from '../../components/headers/ReservedBanner';
import WarpEditSubHeader from '../../components/headers/WarpEditSubHeader';
import ImageWithError from '../../components/ImageWithError';
import FeedbackModal from '../../components/modals/FeedbackModal';
import InputModal from '../../components/modals/InputModal';
import ModelIdentityBanner from '../../components/ModelIdentityBanner';
import HorizontalSlider from '../../components/sliders/HorizontalSlider';
import SuccessLayout from '../../components/SuccessLayout';
import SuperposeAnimationContext from '../../components/SuperposeAnimationContext';
import {
    invalidateFeedbackAction,
    useGetGarmentFeedbacksQuery,
    useGetWarpFeedbacksQuery,
} from '../../services/api/api-feedback';
import { invalidateGetGarmentsAction } from '../../services/api/api-garment';
import {
    invalidategetCrossWarpsAction,
    useGetNewWarpEditMutation,
    useGetWarpEditMutation,
    usePostSaveNewWarpMutation,
    usePostSaveWarpMutation,
    usePutReserveMutation,
} from '../../services/api/api-warp';
import {
    usePostCoherenceKpMutation,
    usePostD2SMutation,
    usePostJKPMutation,
    usePostS2DMutation,
    usePostWarpEditMutation,
} from '../../services/api/api-warp-edit';
import { getClient, setPathBlockState } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import {
    CallbackWarpSave,
    Feedback,
    FeedbackParams,
    JkpResponse,
    KeypointConversionPayload,
    KeypointType,
    Pose,
    WarpKeypoints,
    WarpPayload,
    WarpSavePayload,
} from '../../types/api-types';
import useAsyncQueue from '../../utils/async-queue-hook';
import {
    COMMON_LOCALES,
    DEFAULT_WARPEDIT_STEPS,
    ERROR_LOCALES,
    FEEDBACK_STATUS,
    LAYOUT_LOCALES,
    MAIN_HEADER_HEIGHT,
    PREFILTERS,
    SUB_HEADER_HEIGHT,
    WARP_EDIT_LOCALES,
    WARPEDIT_STEPS,
} from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useFeedbacks from '../../utils/feedback-hook';
import handlePaginationScroll from '../../utils/scroll-pagination-helpers';
import { calculateRealPos, convertApiKeypointsArray } from '../../utils/warp-edit-helpers';
import FeedbackSuperpose from '../FeedbackSuperpose';
import CarouselGridElement from '../preprocessing/gridElements/CarouselGridElement';
import OpacityGridElement from '../preprocessing/gridElements/OpacityGridElement';
import SingleImageGridElement from '../preprocessing/gridElements/SingleImageElement';
import WarpComparison from './WarpComparison';
import WarpGridItem from './WarpGridItem';

const SOURCE_TYPE = 'source';
const DESTINATION_TYPE = 'destination';

// ---- WarpEdit vars ----
let historyUndo: string[] = [];
let historyRedo: string[] = [];
let editPayload: WarpPayload | null = null;
let finalPayload: WarpPayload | null = null;
let crosswarpPayload: WarpPayload | null | undefined = null;
let crosswarpKeypoints: { source: KeypointType[], destination: KeypointType[] } | null = null;
let coherenceKeypoints: { source: KeypointType[], destination: KeypointType[] } | null = null;
let currentPayload: WarpPayload | null = null;
let imageHistory: { payload: string, img: string }[] = [];
const jkpHistory: { torsoKpAndPart: string, jkp: JkpResponse }[] = [];
let intervalId = 0;
let eraseIndex: number[] = [];
let isReserved = true;

export default function WarpEdit() {
    // ---- Utils Hook ----
    const { t } = useTranslation([WARP_EDIT_LOCALES, COMMON_LOCALES, LAYOUT_LOCALES]);
    const toast = useToast();
    const { warpId, garmentId } = useParams();
    const { addToQueue } = useAsyncQueue();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sendSmartWarpFeedback, getSuperposeImagesFromFeedbacks, getVisibleIdsFromFeedbacks } = useFeedbacks();
    const customNavigate = useCustomNavigate();
    const { pathname } = useLocation();

    // ---- Store State ----
    const currentClient = useAppSelector((state) => getClient(state));

    // ---- API ----
    const [getWarpEdit, { data: warpData, isError: isWarpEditError }] = useGetWarpEditMutation();
    const [getNewWarpEdit, { data: newWarpData, isError: isNewWarpEditError }] = useGetNewWarpEditMutation();
    const [postWarpEdit, { data: postData, isLoading: isPostWarpLoading, isError: isPostWarpError }] = usePostWarpEditMutation();
    const [postD2S, { isLoading: isD2SLoading }] = usePostD2SMutation();
    const [postS2D, { isLoading: isS2DLoading }] = usePostS2DMutation();
    const [postJKP, { isLoading: isJKPLoading }] = usePostJKPMutation();
    const [postSaveWarp, { data: saveWarpData, isLoading: isPostSaveWarpLoading, isError: isPostSaveWarpError }] = usePostSaveWarpMutation();
    const [
        postSaveNewWarp,
        { data: saveNewWarpData, isLoading: isPostSaveNewWarpLoading, isError: isPostSaveNewWarpError },
    ] = usePostSaveNewWarpMutation();
    const [putReserve, { data: reserveData, isError: isReserveError }] = usePutReserveMutation();
    const { data: feedbacks } = warpId ? useGetWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', sort: true },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    ) : useGetGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', sort: true },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId },
    );

    const [postResetCross, { isLoading: isResetCrossLoading }] = usePostCoherenceKpMutation();

    // ---- Local State ----
    const [stepString, setStepString] = useState<string>(DEFAULT_WARPEDIT_STEPS[0]);
    const [step, setStep] = useState<number>(0);
    const [stepArray, setStepArray] = useState<string[]>(DEFAULT_WARPEDIT_STEPS);
    const [sourceKpList, setSourceKpList] = useState<KeypointType[]>([]);
    const [destinationKpList, setDestinationKpList] = useState<KeypointType[]>([]);
    const [isKpDrag, setIsKpDrag] = useState<boolean>(false);
    const [hoveredKp, setHoveredKp] = useState<number | null>(null);
    const [eraseMode, setEraseMode] = useState<boolean>(false);
    const [activePart, setActivePart] = useState<string>('torso');
    const [activeTextureKey, setActiveTextureKey] = useState<string>('garment');
    const [visibleValues, setVisibleValues] = useState<string[]>(['torso']);
    const [warpOpacity, setWarpOpacity] = useState<number>(0.5);
    const [warpImg, setWarpImg] = useState<string>();
    const [finalImg, setFinalImg] = useState<string>();
    const [isImageDrag, setIsImageDrag] = useState<boolean>(false);// Set to true when panning was fired and reset to false after onWarpClick is fired
    const [useFinalPayload, setUseFinalPayload] = useState<boolean>(false);
    const [isSmartwarpDisabled, setIsSmartwarpDisabled] = useState<boolean>(false);
    const [isUpperfixDisabled, setIsUpperfixDisabled] = useState<boolean>(false);
    const [blockEdit, setBlockEdit] = useState<boolean>(true);
    const [isResetOpen, setIsResetOpen] = useState<boolean>(false);
    const [comparisonNoSmartWarp, setComparisonNoSmartWarp] = useState<string>();
    const [comparisonSmartWarp, setComparisonSmartWarp] = useState<string>();
    const [showNoSmartModal, setShowNoSmartModal] = useState<boolean>(false);
    const [noSmartReason, setNoSmartReason] = useState<string>();
    const [fetchingOnlyCoord, setFetchingOnlyCoord] = useState<boolean>(false);
    const [carouselIndex, setCarouselIndex] = useState<number>(0);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    const [animateSuperpose, setAnimateSuperpose] = useState<boolean>(true);
    const [moveAllKpMode, setMoveAllKpMode] = useState<boolean>(false);
    const [hadErrorWithPrefix, setHadErrorWithPrefix] = useState<boolean>(false);
    const [visibleFeedbackIds, setVisibleFeedbackIds] = useState<string[]>();
    const [feedbackIdHovered, setFeedbackIdHovered] = useState<string | undefined>();
    const [fullFeedbacks, setFullFeedbacks] = useState<Feedback[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [leftViewValue, setLeftViewValue] = useState<boolean>(true);

    // ---- Need unsorted feedbacks to show in feedback modal and feedback step ----
    const { data: unsortedFeedbacks, isLoading: isUnsortedFeedbackLoading } = warpId ? useGetWarpFeedbacksQuery(
        { clientId: currentClient?.id || '', id: warpId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !warpId },
    ) : useGetGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', page: currentPage },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId },
    );

    // ---- Refs ----
    const sourceKpRef = useRef<KeypointType[]>();
    const destinationKpRef = useRef<KeypointType[]>();

    // FIX FOR CALLBACK
    sourceKpRef.current = sourceKpList;
    destinationKpRef.current = destinationKpList;

    // ---- Memo vars ----
    const data = useMemo(() => {
        if (warpId) {
            return warpData;
        }

        if (garmentId) {
            return newWarpData;
        }

        return null;
    }, [warpId, garmentId, warpData, newWarpData]);

    const saveData = useMemo(() => {
        if (warpId) {
            return saveWarpData;
        }

        if (garmentId) {
            return saveNewWarpData;
        }

        return null;
    }, [warpId, garmentId, saveWarpData, saveNewWarpData]);

    const cutsSrc = useMemo(() => {
        if (!data) {
            return [];
        }

        const result: string[] = [];
        Object.keys(data.garment_cuts).forEach((localKey) => {
            result.push(data.garment_cuts[localKey]);
        });

        return result;
    }, [data]);

    const extrasSrc = useMemo(() => {
        if (!data) {
            return [];
        }

        const result: string[] = [];
        Object.keys(data.model_extras).forEach((localKey) => {
            // ---- We push only the activePart extra or if it's on torso, we put also the legs extras ----
            if (localKey === activePart || (activePart === 'torso' && ['leftLeg', 'rightLeg'].includes(localKey))) {
                result.push(data.model_extras[localKey]);
            }
        });

        return result;
    }, [data, activePart]);

    const guideData = useMemo(() => {
        if (!data) {
            return null;
        }

        if (data.primary && data.model_id === data.primary.model_id) {
            return data.primary;
        }

        if (data.cross) {
            return data.cross;
        }

        return null;
    }, [data]);

    const carouselData = useMemo(() => {
        if (!data) {
            return [];
        }

        const fullArray = [...(data.references || []), ...(data.extras || [])];

        if (data.cross) {
            fullArray.unshift(data.cross);
        }

        if (data.primary) {
            fullArray.unshift(data.primary);
        }

        return fullArray;
    }, [data]);

    // ---- 'garment' is the default key and we add the textures given by the config when we get them ----
    const textureKeys = useMemo(() => {
        let result = ['garment'];
        if (data && data.textures) {
            result = result.concat(Object.keys(data.textures));
        }

        return result;
    }, [data]);

    const isWarpInitialized = useMemo(() => {
        const sourceTorsoKp = sourceKpList.filter((sourceKp) => sourceKp.type === 'torso');
        const destinationTorsoKp = destinationKpList.filter((destinationKp) => destinationKp.type === 'torso');

        if (sourceTorsoKp.length < 2 || destinationTorsoKp.length < 2 || !data) {
            return false;
        }

        return true;
    }, [sourceKpList, destinationKpList, data]);

    const GRID_ITEM_HEIGHT = useMemo(
        () => {
            let offset = 0;
            // ---- Array of condition that show a banner that we need to offset in height ----
            [
                (!isWarpInitialized && !fetchingOnlyCoord),
                (data && data.reservations),
                (feedbacks && data?.warp_status === PREFILTERS.HASFEEDBACK),
            ].forEach((value) => {
                if (value) {
                    offset += 24;
                }
            });

            return `calc(100vh - ${SUB_HEADER_HEIGHT}px - ${MAIN_HEADER_HEIGHT}px ${offset !== 0 ? `- ${offset}px` : ''})`;
        },
        [isWarpInitialized, feedbacks, data],
    );

    const isAnyLoading = useMemo(
        () => isPostWarpLoading || isJKPLoading || isD2SLoading || isS2DLoading || isResetCrossLoading,
        [isPostWarpLoading, isJKPLoading, isD2SLoading, isS2DLoading, isResetCrossLoading],
    );

    // ---- Last reason from data ----
    const lastNoSmartReason = useMemo(() => (data && data.disable_smart_reasons && data.disable_smart_reasons.length > 0
        ? data.disable_smart_reasons[data.disable_smart_reasons.length - 1].content
        : undefined),
    [data]);

    const comparisonTitle = useMemo(() => {
        const baseTitle = t('comparison_images.title', { ns: COMMON_LOCALES });
        if (typeof carouselData[carouselIndex] === 'string' || !carouselData[carouselIndex]) {
            return `${baseTitle}: ${t('comparison_images.EXTRA', { ns: COMMON_LOCALES })}`;
        }

        const dataAsPose = carouselData[carouselIndex] as Pose;

        if (dataAsPose.image_type === 'POSE') {
            return `${baseTitle}: ${t(`comparison_images.${dataAsPose.garment_pose}`, { ns: COMMON_LOCALES })}`;
        }

        return `${baseTitle}: ${t(`comparison_images.${dataAsPose.image_type}`, { ns: COMMON_LOCALES })}`;
    }, [carouselData, carouselIndex]);

    // ---- Images associated to currect step to use in feedbacks ----
    const currentStepImages = useMemo(() => {
        switch (stepString) {
            case WARPEDIT_STEPS.SMART_COMPARISON:
                if (!comparisonNoSmartWarp || !comparisonSmartWarp) {
                    return [];
                }

                return [
                    { label: t('comparison.smart_title_no_select'), src: comparisonSmartWarp },
                    { label: t('comparison.no_smart_title_no_select'), src: comparisonNoSmartWarp },
                ];
            default:
                return [];
        }
    }, [stepString, comparisonSmartWarp, comparisonNoSmartWarp]);

    const overlayFeedbackImages = useMemo(() => {
        if (!fullFeedbacks || !visibleFeedbackIds) {
            return undefined;
        }

        return getSuperposeImagesFromFeedbacks(fullFeedbacks, visibleFeedbackIds, feedbackIdHovered);
    }, [visibleFeedbackIds, fullFeedbacks, feedbackIdHovered]);

    const noSmartPermission = useMemo(() => {
        if (!data) {
            return false;
        }

        return !!data.can_disable_smart;
    }, [data]);

    // ---- Core Functions ----

    // ---- Handle PostWarpEdit Error ----
    const handlePostWarpEditError = (payload: WarpPayload, successCallback?: (resp: string | WarpPayload) => void) => {
        setHadErrorWithPrefix(true);
        postWarpEdit({ payload }).unwrap().then(
            successCallback,
        ).catch((err) => {
            console.error(err);
        });
    };

    // ---- Get the list of kp in the payload format (number[][]) ----
    const getPosArray = (kpList: KeypointType[]) => kpList.map((localKp) => [localKp.x / 100, localKp.y / 100]);

    // ---- Check if input array is 2d or 1d array ----
    const is2dArray = (array: number[] | number[][]) => array.some((item: number[] | number) => Array.isArray(item));

    // ---- Returns the Pos from the response that can be directly or in a 2D array ----
    const getCoordResponsePos = (resp: number[] | number[][]) => {
        if (is2dArray(resp)) {
            // ---- Response in S2D is a list of keypoint (but we only get one) ----
            return (resp as number[][])[0];
        }

        return resp as number[];
    };

    // ---- Handle ImageHistory Add ----
    const imageHistoryAdd = (newHistory: { payload: string, img: string }) => {
        imageHistory.push(newHistory);
        if (imageHistory.length > 10) {
            imageHistory.shift();
        }
    };

    // ---- Set the Elem at index at the end of the array ----
    const imageHistoryUpdateElemAtIndex = (index: number) => {
        const splicedElem = imageHistory.splice(index, 1);
        imageHistory.push(splicedElem[0]);
    };

    const editPayloadVisibility = (payload: WarpPayload) => {
        if (!data) {
            return;
        }

        const localPayload = payload;

        // ---- We get all types of cut present in this warp ----
        const allTypeArray = Object.keys(data.garment_cuts);

        // ---- Handle visibility ----
        allTypeArray.forEach((cutType) => {
            if (cutType === 'torso') {
                // ---- We hide it if it's not in the visible array ----
                if (!visibleValues.includes('torso')) {
                    localPayload.transformations[0].hide = true;
                }
            } else {
                const torsoTransformations = localPayload.transformations[0].transformations;

                if (!torsoTransformations) {
                    return;
                }

                const foundIndex = torsoTransformations.findIndex((secondaryTransform) => secondaryTransform.type === cutType);
                if (!visibleValues.includes(cutType)) {
                    torsoTransformations.splice(foundIndex, 1);
                }
            }
        });
    };

    const editPayloadKeypoints = (
        payload: WarpPayload,
        newSourceKpList: KeypointType[],
        newDestinationKpList: KeypointType[],
        currentActivePart?: string,
    ) => {
        if (!data) {
            return;
        }

        const localPayload = payload;

        // ---- We get all types of cut present in this warp ----
        const allTypeArray = Object.keys(data.garment_cuts);

        // ---- For each type we update the keypoints array with the matching keypoints ----
        allTypeArray.forEach((cutType) => {
            // ---- torso type has a specific array at the root of the payload ----
            if (cutType === 'torso') {
                localPayload.transformations[0].keypoints_source = getPosArray(newSourceKpList.filter((kp) => kp.type === cutType));
                localPayload.transformations[0].keypoints_destination = getPosArray(newDestinationKpList.filter((kp) => kp.type === cutType));

                // ---- Check activePart for index ----
                if (currentActivePart === cutType) {
                    localPayload.transformations[0].index = 1;
                }
            } else {
                const torsoTransformations = payload.transformations[0].transformations;

                if (!torsoTransformations) {
                    return;
                }

                const foundIndex = torsoTransformations.findIndex((secondaryTransform) => secondaryTransform.type === cutType);
                if (foundIndex !== -1) {
                    // ---- We remove the transformation if it's hidden in the visible array ----
                    torsoTransformations[foundIndex].keypoints_source = getPosArray(newSourceKpList.filter((kp) => kp.type === cutType));
                    torsoTransformations[foundIndex].keypoints_destination = getPosArray(
                        newDestinationKpList.filter((kp) => kp.type === cutType),
                    );

                    // ---- Check activePart for index ----
                    if (currentActivePart === cutType) {
                        torsoTransformations[foundIndex].index = 1;
                    }
                }
            }
        });
    };

    // ---- Ajout a l'history ----
    const addToHistory = (historySourceKpList: KeypointType[], historyDestinationKpList: KeypointType[], fromRedo = false) => {
        // ---- Est-ce qu'on peut ajouter ----
        const toAdd = JSON.stringify({ destination: historyDestinationKpList, source: historySourceKpList });
        if ((historyUndo.length && toAdd === historyUndo[historyUndo.length - 1]) || !isWarpInitialized) {
            return;
        }
        historyUndo.push(toAdd);

        // ---- When we add history from redo click we don't reset the whole redo array ----
        if (!fromRedo) {
            historyRedo = [];
        }
    };

    const updateWarp = (
        newSourceKpList: KeypointType[],
        newDestinationKpList: KeypointType[],
        options?: { [key: string]: unknown },
        newActivePart?: string,
    ) => {
        // ---- We need the initial data, payloads and the warp to be initialised to call a warp ----
        if (!data || !finalPayload || !editPayload || !isWarpInitialized) {
            return;
        }

        // ---- We need at least 2 KP pairs ----
        if (newSourceKpList.filter((kp) => kp.type === 'torso').length < 2 || newDestinationKpList.filter((kp) => kp.type === 'torso').length < 2) {
            return;
        }

        // ---- We are not blocked by reservation and have the init warp ----
        if (blockEdit && (postData || isPostWarpLoading)) {
            return;
        }

        // ---- Chose the right payload according to option ----
        let payload = editPayload;
        if (options?.useFinal) {
            payload = finalPayload;
        } else {
            setFinalImg(undefined);
            setUseFinalPayload(false);
        }

        // ---- Need to clone the payload to update it ----
        const clonePayload = JSON.parse(JSON.stringify(payload));

        // ---- Add needed field -----
        clonePayload.transparent = 1;

        // ---- Add the Active texture if needed and not overrided by options param ----
        if (activeTextureKey !== 'garment' && !options?.garment && data.textures) {
            clonePayload.garment = data.textures[activeTextureKey];
        }

        // ---- Remove smart from payload ----
        if (isSmartwarpDisabled) {
            delete clonePayload.smart;
        }

        // ---- Remove fix_upper from payload ----
        if (isUpperfixDisabled) {
            delete clonePayload.transformations[0].fix_upper;
        }

        // ---- Add Keypoints to Payload ----
        editPayloadKeypoints(clonePayload, newSourceKpList, newDestinationKpList, newActivePart || activePart);

        // ---- Edit the payload according to the visibility array ----
        editPayloadVisibility(clonePayload);

        // ---- Add the options that we have as param of the function to the payload ----
        if (options) {
            Object.keys(options).forEach((optionKey) => {
                // ---- Don't add options used only for web app ----
                if (!['useFinal'].includes(optionKey)) {
                    clonePayload[optionKey] = options[optionKey];
                }
            });
        }

        addToHistory(newSourceKpList, newDestinationKpList);

        // ---- Update currentPayload ----
        currentPayload = clonePayload;

        // ---- We don't load the new warp if we have it in cache ----
        const foundIndex = imageHistory.findIndex((history) => history.payload === JSON.stringify(clonePayload));
        if (foundIndex !== -1) {
            setWarpImg(imageHistory[foundIndex].img);

            imageHistoryUpdateElemAtIndex(foundIndex);

            return;
        }

        const successCallback = (resp: string | WarpPayload) => {
            if (resp && isWarpInitialized) {
                // ---- Check if payload is the current one ----
                if (currentPayload === clonePayload) {
                    setWarpImg(resp as string);
                }

                // ---- Handle ImageHistory ----
                imageHistoryAdd({ img: resp as string, payload: JSON.stringify(clonePayload) });
            }
        };

        const queuePostWarpEdit = () => postWarpEdit(
            { payload: clonePayload, ...(!hadErrorWithPrefix ? { urlPrefix: data.edit_prefix } : null) },
        ).unwrap()
            .then(successCallback)
            .catch(() => handlePostWarpEditError(clonePayload, successCallback));

        addToQueue(queuePostWarpEdit).catch((err) => {
            throw new Error(err);
        });
    };

    const updateWarpWithCurrentState = (options?: { [key: string]: unknown }) => {
        updateWarp(sourceKpList, destinationKpList, options);
    };

    const handleJkpHistoryAdd = (jkp: JkpResponse) => {
        jkpHistory.push({
            jkp,
            torsoKpAndPart: JSON.stringify(sourceKpList.filter((kp) => kp.type === 'torso'))
                + JSON.stringify(destinationKpList.filter((kp) => kp.type === 'torso'))
                + activePart,
        });

        if (jkpHistory.length > 10) {
            jkpHistory.shift();
        }
    };

    const getCurrentJkpHistory = () => jkpHistory.find(
        (history) => history.torsoKpAndPart === JSON.stringify(sourceKpList.filter((kp) => kp.type === 'torso'))
            + JSON.stringify(destinationKpList.filter((kp) => kp.type === 'torso'))
            + activePart,
    );

    const handlePostProjection = (
        projectionType: 'D2S' | 'S2D',
        payload: KeypointConversionPayload,
        realPos: { x: number, y: number },
        urlPrefix?: string,
    ) => {
        const isActivePartVisible = visibleValues.includes(activePart);
        const projectionFunction = projectionType === 'D2S' ? postD2S : postS2D;

        // ---- Add JPK when activePart is not torso ----
        if (activePart !== 'torso') {
            // ---- Get JKP according to torsoKp ----
            const currentJkp = getCurrentJkpHistory();

            if (!currentJkp) {
                return;
            }

            // ---- Add JKP in payload ----
            payload.keypoints_destination.push(...currentJkp.jkp.jointure_keypoints_destination);
            payload.keypoints_source.push(...currentJkp.jkp.jointure_keypoints_source);
        }

        const successCallback = (resp: number[] | number[][]) => {
            const convertedPos = getCoordResponsePos(resp);

            if (!sourceKpRef.current || !destinationKpRef.current) {
                return;
            }

            // ---- 2 variable storing the new Source and Destination array ----
            const newSourceKpList = projectionType === 'D2S'
                ? [...sourceKpRef.current, {
                    type: activePart,
                    x: convertedPos[0] * 100,
                    y: convertedPos[1] * 100,
                }]
                : [...sourceKpRef.current, { ...realPos, type: activePart }];
            const newDestinationKpList = projectionType === 'D2S'
                ? [...destinationKpRef.current, { ...realPos, type: activePart }]
                : [...destinationKpRef.current, {
                    type: activePart,
                    x: convertedPos[0] * 100,
                    y: convertedPos[1] * 100,
                }];

            // ---- Update of state and history ----
            setSourceKpList(newSourceKpList);
            setDestinationKpList(newDestinationKpList);
            addToHistory(newSourceKpList, newDestinationKpList);

            // ---- Call the warpApi with new keypoints if the active part is visible ----
            if (isActivePartVisible) {
                updateWarp(newSourceKpList, newDestinationKpList);
            } else {
                setVisibleValues([...visibleValues, activePart]);
            }
        };

        // ---- We fetch the projection on the Destination ----
        projectionFunction({ payload, ...(!hadErrorWithPrefix ? { urlPrefix } : null) }).unwrap().then(
            successCallback,
        ).catch(() => {
            setHadErrorWithPrefix(true);
            projectionFunction({ payload }).unwrap()
                .then(successCallback)
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const handleOnWarpClick = (e: React.MouseEvent, imageId: string) => {
        const garmentWarpImageElement = document.getElementById(imageId);

        if (!garmentWarpImageElement || isImageDrag || eraseMode || !data) {
            setIsImageDrag(false);

            return;
        }

        // ---- If edition is blocked ----
        if (blockEdit) {
            return;
        }

        // ---- If we are in initialisation phase we check if we clicked on the good source ----
        if (!isWarpInitialized) {
            let errorKey: string | null = null;
            if (imageId === 'source' && sourceKpList.length > destinationKpList.length) {
                errorKey = 'warp_init.need_destination';
            }

            if (imageId === 'destination' && destinationKpList.length > sourceKpList.length) {
                errorKey = 'warp_init.need_source';
            }

            if (imageId === 'destination' && destinationKpList.length === sourceKpList.length) {
                errorKey = 'warp_init.first_source';
            }

            // ---- Show a toast with the matching error ----
            if (errorKey) {
                toast({
                    isClosable: true,
                    status: 'error',
                    title: t(errorKey, { ns: ERROR_LOCALES }),
                });

                return;
            }
        }

        const rect = garmentWarpImageElement.getBoundingClientRect();
        const realPos = calculateRealPos(e.clientX, e.clientY, rect);

        // ---- Common payload between S2D and D2S ----
        const payload = {
            coords: [realPos.x / 100, realPos.y / 100],
            garment: data.garment_image_url,
            keypoints_destination: getPosArray(destinationKpList.filter((kp) => kp.type === activePart)),
            keypoints_source: getPosArray(sourceKpList.filter((kp) => kp.type === activePart)),
            model: data.model_image_url,
        };

        switch (imageId) {
            case SOURCE_TYPE:
                // ---- We only update clicked kp when warp is not initialized ----
                if (!isWarpInitialized) {
                    setSourceKpList([...sourceKpList, { ...realPos, type: activePart }]);

                    break;
                }

                // ---- Fetch JKP if activePart is not torso and we don't have them already ----
                if (!getCurrentJkpHistory() && activePart !== 'torso') {
                    // ---- Create and set the payload for JKP ----
                    const clonePayloadJkp: WarpPayload = JSON.parse(JSON.stringify(data.edit_payload));
                    editPayloadKeypoints(clonePayloadJkp, sourceKpList, destinationKpList);

                    // ---- If here is only a torso transform we can't get jkp ----
                    if (!clonePayloadJkp.transformations[0].transformations) {
                        break;
                    }

                    // ---- Set only the active part transform in the payload ----
                    clonePayloadJkp.transformations[0].transformations = clonePayloadJkp.transformations[0].transformations?.filter(
                        (transfo) => transfo.type === activePart,
                    );

                    // ---- Remove KP for this transform ----
                    const currentTransformation = clonePayloadJkp.transformations[0].transformations[0];
                    currentTransformation.keypoints_source = [];
                    currentTransformation.keypoints_destination = [];

                    postJKP(clonePayloadJkp).unwrap().then((respJkp) => {
                        handleJkpHistoryAdd(respJkp);
                        handlePostProjection('S2D', payload, realPos, data.edit_prefix);
                    }).catch((err) => {
                        console.error(err);
                    });
                } else {
                    handlePostProjection('S2D', payload, realPos, data.edit_prefix);
                }

                break;
            case DESTINATION_TYPE:
                // ---- We only update clicked kp when warp is not initialized ----
                if (!isWarpInitialized) {
                    setDestinationKpList([...destinationKpList, { ...realPos, type: activePart }]);

                    break;
                }

                // ---- Fetch JKP if activePart is not torso and we don't have them already ----
                if (!getCurrentJkpHistory() && activePart !== 'torso') {
                    // ---- Create and set the payload for JKP ----
                    const clonePayloadJkp: WarpPayload = JSON.parse(JSON.stringify(data.edit_payload));
                    editPayloadKeypoints(clonePayloadJkp, sourceKpList, destinationKpList);

                    // ---- If here is only a torso transform we can't get jkp ----
                    if (!clonePayloadJkp.transformations[0].transformations) {
                        break;
                    }

                    // ---- Set only the active part transform in the payload ----
                    clonePayloadJkp.transformations[0].transformations = clonePayloadJkp.transformations[0].transformations?.filter(
                        (transfo) => transfo.type === activePart,
                    );

                    // ---- Remove KP for this transform ----
                    const currentTransformation = clonePayloadJkp.transformations[0].transformations[0];
                    currentTransformation.keypoints_source = [];
                    currentTransformation.keypoints_destination = [];

                    postJKP(clonePayloadJkp).unwrap().then((respJkp) => {
                        handleJkpHistoryAdd(respJkp);
                        handlePostProjection('D2S', payload, realPos, data.edit_prefix);
                    }).catch((err) => {
                        console.error(err);
                    });
                } else {
                    handlePostProjection('D2S', payload, realPos, data.edit_prefix);
                }

                break;
        }
    };

    const updateKp = (index: number, newPos: KeypointType, kpType: string) => {
        let errorUpdate = false;

        // ---- Update function used in the map call of the kpLists ----
        const updateFunction = (kp: KeypointType, i: number, newKp: KeypointType, offset?: { x: number, y: number }) => {
            if (i === index) {
                // Update the modifed Kp
                return newKp;
            }

            // ---- If we have the moveAllKpMode ON ----
            if (offset && moveAllKpMode) {
                if (kp.x - offset.x < 0 || kp.x - offset.x > 100) {
                    errorUpdate = true;
                }

                return { type: kp.type, x: kp.x - offset.x, y: kp.y - offset.y };
            }

            // The rest haven't changed
            return kp;
        };

        // ---- We need data to fill the conversion payloads ----
        if (!data) {
            return;
        }

        let newKpList: KeypointType[] = [];
        switch (kpType) {
            case SOURCE_TYPE:
                // ---- If the point did not move we do nothing ----
                if (newPos.x === sourceKpList[index].x && newPos.y === sourceKpList[index].y) {
                    break;
                }

                newKpList = sourceKpList.map(
                    (kp, i) => updateFunction(kp, i, newPos, { x: sourceKpList[index].x - newPos.x, y: sourceKpList[index].y - newPos.y }),
                );

                // --- Don't update if error ----
                if (errorUpdate) {
                    break;
                }

                setSourceKpList(newKpList);
                addToHistory(newKpList, destinationKpList);
                updateWarp(newKpList, destinationKpList);
                break;
            case DESTINATION_TYPE:
                // ---- If the point did not move we do nothing ----
                if (newPos.x === destinationKpList[index].x && newPos.y === destinationKpList[index].y) {
                    break;
                }

                newKpList = destinationKpList.map(
                    (kp, i) => updateFunction(kp, i, newPos, { x: destinationKpList[index].x - newPos.x, y: destinationKpList[index].y - newPos.y }),
                );

                // --- Don't update if error ----
                if (errorUpdate) {
                    break;
                }

                setDestinationKpList(newKpList);
                addToHistory(sourceKpList, newKpList);
                updateWarp(sourceKpList, newKpList);

                break;
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.altKey) {
            setEraseMode(true);
        }

        if (e.shiftKey) {
            setMoveAllKpMode(true);
        }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
        if (!e.altKey) {
            setEraseMode(false);
        }

        if (!e.shiftKey) {
            setMoveAllKpMode(false);
        }
    };

    // ---- Remove a single KP from index ----
    const removeKp = (removeKpIndex: number) => {
        const newSourceKpList = sourceKpList.filter((_, index) => index !== removeKpIndex);
        setSourceKpList(newSourceKpList);

        const newDestinationKpList = destinationKpList.filter((_, index) => index !== removeKpIndex);
        setDestinationKpList(newDestinationKpList);

        setHoveredKp(null);
        addToHistory(newSourceKpList, newDestinationKpList);

        if (isWarpInitialized) {
            updateWarp(newSourceKpList, newDestinationKpList);
        }
    };

    // ---- Remove KP in the zone of the Eraser ----
    const handleErase = (e: React.MouseEvent) => {
        const target = (e.target as Element);

        // ---- Invalid requirement to erase ----
        if (!e.altKey || ![SOURCE_TYPE, DESTINATION_TYPE].includes(target.id) || e.buttons !== 1 || blockEdit) {
            return;
        }

        // ---- We calculate the minPos and maxPos of our eraser Icon ----
        const rect = target.getBoundingClientRect();
        const realPosMin = calculateRealPos(e.clientX - 25, e.clientY - 25, rect);
        const realPosMax = calculateRealPos(e.clientX + 25, e.clientY + 25, rect);

        // ---- We chose kpList according to which image we are hovering ----
        const kpToHandle = target.id === SOURCE_TYPE ? sourceKpList : destinationKpList;

        // ---- Check which KP are in the Erase area and store it's index ----
        kpToHandle.forEach((kp, index) => {
            if (kp.x >= realPosMin.x && kp.x <= realPosMax.x && kp.y >= realPosMin.y && kp.y <= realPosMax.y) {
                if (!eraseIndex.includes(index)) {
                    eraseIndex.push(index);
                }

                // ---- Remove DOM element directly here and when erase finish we update state ----
                document.getElementById(`kp_${target.id}_${index}`)?.remove();
                document.getElementById(`kp_${target.id === SOURCE_TYPE ? DESTINATION_TYPE : SOURCE_TYPE}_${index}`)?.remove();
            }
        });
    };

    // ---- Called when we release the clic and according to the hasEraseMulti and eraseMode update the warp ----
    const handleEraseEnded = () => {
        if (eraseIndex.length > 0) {
            // ---- We update the history and set the hasErasedMulti boolean only when we erase a KP ----
            // ---- Remove Source KP detected before ----
            const newSourceKpList = sourceKpList.filter((_, index) => !eraseIndex.includes(index));
            setSourceKpList(newSourceKpList);

            // ---- Remove Destination KP detected before ----
            const newDestinationKpList = destinationKpList.filter((_, index) => !eraseIndex.includes(index));
            setDestinationKpList(newDestinationKpList);

            addToHistory(newSourceKpList, newDestinationKpList);

            eraseIndex = [];

            if (isWarpInitialized) {
                updateWarp(newSourceKpList, newDestinationKpList);
            }
        }
    };

    const handleUndo = () => {
        if (historyUndo.length > 1) {
            // ---- Gestion du redo ----
            historyRedo.push(historyUndo.pop() as string);

            // ---- Mise a jours des keypoints ----
            const goBack = JSON.parse(historyUndo[historyUndo.length - 1]);
            const destinationKeypoints = JSON.parse(JSON.stringify(goBack.destination));
            const sourceKeypoints = JSON.parse(JSON.stringify(goBack.source));

            setSourceKpList(sourceKeypoints);
            setDestinationKpList(destinationKeypoints);

            updateWarp(sourceKeypoints, destinationKeypoints);
        }
    };

    const handleRedo = () => {
        if (historyRedo.length > 0) {
            // ---- Mise a jours des keypoints ----
            const goAgain = JSON.parse(historyRedo.pop() as string);
            const destinationKeypoints = JSON.parse(JSON.stringify(goAgain.destination));
            const sourceKeypoints = JSON.parse(JSON.stringify(goAgain.source));

            addToHistory(sourceKeypoints, destinationKeypoints, true);
            setSourceKpList(sourceKeypoints);
            setDestinationKpList(destinationKeypoints);

            updateWarp(sourceKeypoints, destinationKeypoints);
        }
    };

    const handleTextureChange = (newTextureKey: string) => {
        setActiveTextureKey(newTextureKey);
        if (data && data.textures) {
            updateWarp(
                sourceKpList,
                destinationKpList,
                {
                    garment: newTextureKey !== 'garment'
                        ? data.textures[newTextureKey]
                        : ((useFinalPayload ? finalPayload : editPayload))?.garment,
                },
            );
        }
    };

    const handleVisibleCheck = (e: React.ChangeEvent<HTMLInputElement>, cutKey: string) => {
        if (e.target.checked === false) {
            return setVisibleValues(
                visibleValues.filter((value) => value !== cutKey),
            );
        }

        return setVisibleValues([...visibleValues, cutKey]);
    };

    const handleSmartwarpCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUseFinalPayload(!!e.target.checked);
        updateWarp(sourceKpList, destinationKpList, { useFinal: !!e.target.checked });
    };

    const handleDisableSmartwarpCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsSmartwarpDisabled(!!e.target.checked);
    };

    const handleDisableUpperfix = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsUpperfixDisabled(!!e.target.checked);
    };

    const handleFinalRequest = () => {
        if (!data) {
            return;
        }

        // ---- Reset previous finalImg if it exists ----
        if (finalImg) {
            setFinalImg(undefined);
        }

        // ---- Need to clone the payload to update it ----
        const clonePayload = JSON.parse(JSON.stringify(finalPayload));

        // ---- Remove smart from payload ----
        if (isSmartwarpDisabled) {
            delete clonePayload.smart;
        }

        // ---- Remove fix_upper from payload ----
        if (isUpperfixDisabled) {
            delete clonePayload.transformations[0].fix_upper;
        }

        // ---- Add Keypoints to Payload ----
        editPayloadKeypoints(clonePayload, sourceKpList, destinationKpList);

        // ---- Check Image History FOR FINAL IMG----
        const finalIndex = imageHistory.findIndex((history) => history.payload === JSON.stringify(clonePayload));
        if (finalIndex !== -1) {
            setFinalImg(imageHistory[finalIndex].img);
        } else {
            const successCallback = (resp: string | WarpPayload) => {
                setFinalImg(resp as string);
                imageHistoryAdd({ img: resp as string, payload: JSON.stringify(clonePayload) });
            };

            // ---- Full final warp call ----
            postWarpEdit({ payload: clonePayload, ...(!hadErrorWithPrefix ? { urlPrefix: data.edit_prefix } : null) })
                .unwrap()
                .then(successCallback)
                .catch(() => handlePostWarpEditError(clonePayload, successCallback));
        }

        // ---- Clone again the payload because it's editing the request above if we don't ----
        const payloadGarmentOnly = JSON.parse(JSON.stringify(clonePayload));
        payloadGarmentOnly.transparent = 1;

        // ---- Check Image History FOR WARP IMG----
        const warpIndex = imageHistory.findIndex((history) => history.payload === JSON.stringify(payloadGarmentOnly));
        if (warpIndex !== -1) {
            setWarpImg(imageHistory[warpIndex].img);

            return;
        }

        const successCallback = (resp: string | WarpPayload) => {
            setWarpImg(resp as string);
            imageHistoryAdd({ img: resp as string, payload: JSON.stringify(payloadGarmentOnly) });
        };

        // ---- Final warp only garment ----
        postWarpEdit({ payload: payloadGarmentOnly, ...(!hadErrorWithPrefix ? { urlPrefix: data.edit_prefix } : null) }).unwrap()
            .then(successCallback)
            .catch(() => handlePostWarpEditError(payloadGarmentOnly, successCallback));
    };

    const handleSaveWarp = (overrideDisableSmartValue?: boolean, callbackSave?: CallbackWarpSave) => {
        if (!data || !currentClient) {
            return;
        }

        // ---- Choose between new or existing warp post method ----
        const postSave = warpId ? postSaveWarp : postSaveNewWarp;

        // ---- Payload init ----
        const payload: WarpSavePayload = { keypoints: {} };

        // ---- We get all types of cut present in this warp ----
        const allTypeArray = Object.keys(data.garment_cuts);

        // ---- Add the kp for each cutType ----
        allTypeArray.forEach((cutType) => {
            // ---- NEED SOURCE BEFORE DESTINATION SO WE DEACTIVAE ESLINT RULE HERE ----
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            payload.keypoints[cutType] = { source: [], destination: [] };
            payload.keypoints[cutType].source = getPosArray(sourceKpList.filter((sourceKp) => sourceKp.type === cutType));
            payload.keypoints[cutType].destination = getPosArray(destinationKpList.filter((destinationKp) => destinationKp.type === cutType));
        });

        // ---- Add smart value ----
        payload.disable_smart = (overrideDisableSmartValue !== undefined ? overrideDisableSmartValue : isSmartwarpDisabled);

        // ---- Add reason is smart disabled ----
        if (isSmartwarpDisabled && noSmartReason) {
            payload.disable_smart_reason = noSmartReason;
        }

        // ---- Execute save POST ----
        postSave({ clientId: currentClient.id, id: (warpId || garmentId || ''), payload }).unwrap().then((resp) => {
            if (callbackSave) {
                callbackSave(resp);
            }

            if (resp.errors) {
                resp.errors.forEach((err) => {
                    toast({
                        isClosable: true,
                        status: 'error',
                        title: err,
                    });
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const handleComparisonStep = () => {
        if (!data) {
            return;
        }

        // ---- Need to clone the payload to update it ----
        const clonePayload = JSON.parse(JSON.stringify(finalPayload));

        // ---- Remove fix_upper from payload ----
        if (isUpperfixDisabled) {
            delete clonePayload.transformations[0].fix_upper;
        }

        // ---- Add Keypoints to Payload ----
        editPayloadKeypoints(clonePayload, sourceKpList, destinationKpList);

        // ---- Check Image History for warp with SMART ----
        const smartIndex = imageHistory.findIndex((history) => history.payload === JSON.stringify(clonePayload));
        if (smartIndex !== -1) {
            setComparisonSmartWarp(imageHistory[smartIndex].img);
        } else {
            const successCallback = (resp: string | WarpPayload) => {
                setComparisonSmartWarp(resp as string);
                imageHistoryAdd({ img: resp as string, payload: JSON.stringify(clonePayload) });
            };
            // ---- Smart warp call ----
            postWarpEdit({ payload: clonePayload, ...(!hadErrorWithPrefix ? { urlPrefix: data.edit_prefix } : null) }).unwrap()
                .then(successCallback)
                .catch(() => handlePostWarpEditError(clonePayload, successCallback));
        }

        // ---- Clone again the payload because it's editing the request above if we don't ----
        const payloadNoSmartOnly = JSON.parse(JSON.stringify(clonePayload));
        delete payloadNoSmartOnly.smart;

        // ---- Check Image History warp with NO SMART----
        const noSmartIndex = imageHistory.findIndex((history) => history.payload === JSON.stringify(payloadNoSmartOnly));
        if (noSmartIndex !== -1) {
            setComparisonNoSmartWarp(imageHistory[noSmartIndex].img);

            return;
        }

        const successCallback = (resp: string | WarpPayload) => {
            setComparisonNoSmartWarp(resp as string);
            imageHistoryAdd({ img: resp as string, payload: JSON.stringify(payloadNoSmartOnly) });
        };

        // ---- No smart warp call ----
        postWarpEdit({ payload: payloadNoSmartOnly, ...(!hadErrorWithPrefix ? { urlPrefix: data.edit_prefix } : null) }).unwrap()
            .then(successCallback)
            .catch(() => handlePostWarpEditError(payloadNoSmartOnly, successCallback));
    };

    const handleOnCloseComparisonModal = () => {
        setShowNoSmartModal(false);
    };

    const changeStep = (newStep: string) => {
        // ---- Step updates ----
        const foundStepIndex = stepArray.findIndex((localStep) => localStep === newStep);
        setStep(foundStepIndex);
        setStepString(newStep);

        setWarpImg(undefined);
        setUseFinalPayload(false);
        switch (newStep) {
            case WARPEDIT_STEPS.EDIT:
                setCarouselIndex(0);
                setFinalImg(undefined);
                setComparisonNoSmartWarp(undefined);
                setComparisonSmartWarp(undefined);
                updateWarpWithCurrentState({ useFinal: false });
                break;
            case WARPEDIT_STEPS.SMART_COMPARISON:
                handleComparisonStep();
                break;
            case WARPEDIT_STEPS.SIZING:
                break;
            case WARPEDIT_STEPS.FINAL_CHECK:
                setCarouselIndex(0);
                handleFinalRequest();
                break;
            case WARPEDIT_STEPS.FINISH:
                handleSaveWarp();
                break;
        }
    };

    const handleStepChange = (newStep: number) => {
        // ---- We don't change the step if we need to show the no smart modal ----
        if (isSmartwarpDisabled && stepString === WARPEDIT_STEPS.SMART_COMPARISON) {
            return setShowNoSmartModal(true);
        }

        return changeStep(stepArray[newStep]);
    };

    const handleComparisonValidation = (message?: string) => {
        setNoSmartReason(message);
        setShowNoSmartModal(false);
        changeStep(stepArray[step + 1]);
    };

    const handleActivePartChange = (newActivePart: string) => {
        setActivePart(newActivePart);

        // ---- We call a new warp with the index on the new activePart ----
        updateWarp(sourceKpList, destinationKpList, undefined, newActivePart);

        // ---- We add the new active part if it was not visible ----
        if (!visibleValues.includes(newActivePart)) {
            setVisibleValues([...visibleValues, newActivePart]);
        }
    };

    const getOnlyCoord = (
        payload: WarpPayload, garmentCuts: string[],
        callback?: (sourceKps: KeypointType[], destinationKps: KeypointType[]) => void,
    ) => {
        // ---- Block Edit while we fetch the crosswarp points ----
        setBlockEdit(true);

        // ---- Set local state to hide init banner ----
        setFetchingOnlyCoord(true);

        // ---- Empty the KP before fetching the crossKP so it fetches the warp afterward ----
        setSourceKpList([]);
        setDestinationKpList([]);

        // ---- Var init ----
        let localSourceKps: KeypointType[] = [];
        let localDestinationKps: KeypointType[] = [];

        postWarpEdit({ payload: { ...payload, only_coords: true } }).unwrap().then((crosswarpResp) => {
            // ---- Unlock the Edit when finished ----
            setBlockEdit(false);

            // ---- Cast the response as it usually is a string (the warp img) ----
            const castedResp = crosswarpResp as WarpPayload;

            // ---- For each type we update the keypoints array with the matching keypoints ----
            garmentCuts.forEach((cutType) => {
                // ---- torso type has a specific array at the root of the payload ----
                if (cutType === 'torso') {
                    localSourceKps = localSourceKps.concat(
                        convertApiKeypointsArray(castedResp.transformations[0].keypoints_source, cutType),
                    );
                    localDestinationKps = localDestinationKps.concat(
                        convertApiKeypointsArray(castedResp.transformations[0].keypoints_destination, cutType),
                    );
                } else {
                    const torsoTransformations = castedResp.transformations[0].transformations;

                    if (!torsoTransformations) {
                        return;
                    }

                    const foundIndex = torsoTransformations.findIndex((secondaryTransform) => secondaryTransform.type === cutType);
                    if (foundIndex !== -1) {
                        localSourceKps = localSourceKps.concat(
                            convertApiKeypointsArray(torsoTransformations[foundIndex].keypoints_source, cutType),
                        );
                        localDestinationKps = localDestinationKps.concat(
                            convertApiKeypointsArray(torsoTransformations[foundIndex].keypoints_destination, cutType),
                        );
                    }
                }
            });

            // ---- Update the KPs ----
            setSourceKpList(localSourceKps);
            setDestinationKpList(localDestinationKps);

            // ---- Update local state ----
            setFetchingOnlyCoord(false);

            if (callback) {
                callback(localSourceKps, localDestinationKps);
            }
        }).catch(() => {
            // ---- Unlock the Edit even if we have an error ----
            setBlockEdit(false);

            // ---- Update local state ----
            setFetchingOnlyCoord(false);
        });
    };

    // ---- Get the Keypoints of the Smart warp ----
    const handlePostSmartKp = () => {
        // ---- Return if no data ----
        if (!data) {
            return;
        }

        // ---- Need to clone the payload to update it ----
        const clonePayload = JSON.parse(JSON.stringify(finalPayload));

        // ---- Remove smart from payload ----
        if (isSmartwarpDisabled) {
            delete clonePayload.smart;
        }

        // ---- Remove fix_upper from payload ----
        if (isUpperfixDisabled) {
            delete clonePayload.transformations[0].fix_upper;
        }

        // ---- Add Keypoints to Payload ----
        editPayloadKeypoints(clonePayload, sourceKpList, destinationKpList);

        getOnlyCoord(clonePayload, Object.keys(data.garment_cuts));
    };

    const crosswarpKeypointCallback = (sourceKps: KeypointType[], destinationKps: KeypointType[]) => {
        // ---- Set the var as this response is reusable ----
        crosswarpKeypoints = { destination: destinationKps, source: sourceKps };
    };

    const handleResetFinal = () => {
        // ---- Close the tooltip ----
        setIsResetOpen(false);

        // ---- If we have the CrossKP already we use them ----
        if (crosswarpKeypoints) {
            setSourceKpList(crosswarpKeypoints.source);
            setDestinationKpList(crosswarpKeypoints.destination);
            updateWarp(crosswarpKeypoints.source, crosswarpKeypoints.destination);

            return;
        }

        // ---- If we have the crosswarp payload and the edit data we fetch the cross KPs ----
        if (crosswarpPayload && data) {
            getOnlyCoord(crosswarpPayload, Object.keys(data.garment_cuts), crosswarpKeypointCallback);
        }
    };

    const handleResetCross = () => {
        // ---- Close the tooltip ----
        setIsResetOpen(false);

        // ---- We need the crosswarpPayload to do a cross reset ----
        if (crosswarpPayload) {
            // ---- If we already have the coherence KP we use them ----
            if (coherenceKeypoints) {
                setSourceKpList(coherenceKeypoints.source);
                setDestinationKpList(coherenceKeypoints.destination);
                updateWarp(coherenceKeypoints.source, coherenceKeypoints.destination);

                return;
            }

            // ---- Var init ----
            let localSourceKps: KeypointType[] = [];
            let localDestinationKps: KeypointType[] = [];

            // ---- Block Edit while we fetch the new KPs ----
            setBlockEdit(true);

            postResetCross({ payload: crosswarpPayload }).unwrap().then((resp) => {
                // ---- Unlock the Edit when finished ----
                setBlockEdit(false);

                // ---- We build the new KP lists from the response ----
                Object.keys(resp).forEach((garmentCut) => {
                    localSourceKps = localSourceKps.concat(
                        convertApiKeypointsArray(resp[garmentCut].keypoints_source, garmentCut),
                    );
                    localDestinationKps = localDestinationKps.concat(
                        convertApiKeypointsArray(resp[garmentCut].keypoints_destination, garmentCut),
                    );
                });

                // ---- Set the reusable var ----
                coherenceKeypoints = { destination: localDestinationKps, source: localSourceKps };

                // ---- Update KP list and warp ----
                setSourceKpList(localSourceKps);
                setDestinationKpList(localDestinationKps);
                updateWarp(localSourceKps, localDestinationKps);
            }).catch(() => {
                // ---- Unlock the Edit even if we have an error ----
                setBlockEdit(false);
            });
        }
    };

    const handleResetPart = (part: string) => {
        // ---- Close the tooltip ----
        setIsResetOpen(false);

        // ---- If it's torso we reset all ----
        if (part === 'torso') {
            setWarpImg(undefined);
            setSourceKpList([]);
            setDestinationKpList([]);
            addToHistory([], []);

            return;
        }

        // ---- Var init ----
        const localSourceKps: KeypointType[] = sourceKpList.filter((kp) => kp.type !== part);
        const localDestinationKps: KeypointType[] = destinationKpList.filter((kp) => kp.type !== part);

        // ---- Update Kp lists, history and warp ----
        setSourceKpList(localSourceKps);
        setDestinationKpList(localDestinationKps);
        addToHistory(localSourceKps, localDestinationKps);
        updateWarp(localSourceKps, localDestinationKps);
    };

    const handleFeedbackSuccess = () => {
        setFeedbackModalOpen(false);

        /**
         * We invalidate the garments and crosswarp so we have the updated version in the list (if elesticsearch is fast enough)
         */
        dispatch(invalidateGetGarmentsAction);
        dispatch(invalidategetCrossWarpsAction);
        dispatch(invalidateFeedbackAction);

        navigate(-1);
    };

    const sendFeedback = (
        params: FeedbackParams,
    ) => new Promise((resolve: (returnValue: void) => void) => {
        // ---- First we save the warp ----
        handleSaveWarp(false, (resp) => {
            // ---- We need the warp id and current client to post the feedback ----
            if (resp.errors || !resp.warp_id || !currentClient) {
                return;
            }

            // ---- POST feedback ----
            sendSmartWarpFeedback({ ...params, warpId: resp.warp_id }, handleFeedbackSuccess).catch((err) => {
                console.error(err);
            });
            resolve();
        });
    });

    // ---- Convert camelCase to snake_case ----
    const camelCaseToSnakeCase = (camelStr: string) => {
        // ---- Split the string at each Uppercase without removing the matching part ----
        const splitStr = camelStr.split(/(?=[A-Z])/);

        // ---- Join and convert the whole string to lower case ----
        return splitStr.join('_').toLowerCase();
    };

    const handleOnGarmentcutsChange = (newValue: boolean) => {
        if (!data?.model_garmentcuts) {
            return null;
        }

        if (newValue) {
            return updateWarp(sourceKpList, destinationKpList);
        }

        const option: { [key: string]: undefined } = {};
        Object.keys(data?.model_garmentcuts).forEach((cut) => {
            option[`${camelCaseToSnakeCase(cut)}_cut`] = undefined;
        });

        return updateWarp(sourceKpList, destinationKpList, option);
    };

    const handleFeedbackClick = (newFeedbackId: string) => {
        if (visibleFeedbackIds?.includes(newFeedbackId)) {
            setVisibleFeedbackIds(visibleFeedbackIds?.filter((feedbackId) => feedbackId !== newFeedbackId));

            return;
        }

        setVisibleFeedbackIds(visibleFeedbackIds ? [...visibleFeedbackIds, newFeedbackId] : [newFeedbackId]);
    };

    const handleOnScrollFeedbacks = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80) {
            return;
        }
        handlePaginationScroll(
            isUnsortedFeedbackLoading,
            currentPage,
            () => setCurrentPage(currentPage + 1), unsortedFeedbacks, fullFeedbacks.length,
        );
    };

    // ---- Use Effects ----
    useEffect(() => {
        // ---- Handle Erase Mode activation ----
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);

            // ---- Clear History ----
            historyRedo = [];
            historyUndo = [];

            // ---- Clear data ----
            editPayload = null;
            finalPayload = null;
            crosswarpPayload = null;
            crosswarpKeypoints = null;
            coherenceKeypoints = null;
            imageHistory = [];
            isReserved = true;
        };
    }, []);

    useEffect(() => {
        if (isWarpInitialized && stepString === WARPEDIT_STEPS.EDIT) {
            updateWarpWithCurrentState();
        }
    }, [visibleValues, isSmartwarpDisabled, isUpperfixDisabled]);

    useEffect(() => {
        const getWarpEditFunction = warpId ? getWarpEdit : getNewWarpEdit;
        if (currentClient && (warpId || garmentId)) {
            getWarpEditFunction({ clientId: currentClient.id, id: (warpId || garmentId || '') }).unwrap().then((resp) => {
                // ---- Set Local states to response values ----
                setVisibleValues(Object.keys(resp.garment_cuts));

                // ---- We check if we can disable smart to set the default values ----
                if (resp.can_disable_smart) {
                    setIsSmartwarpDisabled(resp.disable_smart);
                    setIsUpperfixDisabled(!!resp.disable_upper_fix);
                }

                let localSourceKps: KeypointType[] = [];
                let localDestinationKps: KeypointType[] = [];

                editPayload = resp.edit_payload;
                finalPayload = resp.final_payload;
                crosswarpPayload = resp.crosswarp_payload;

                if (resp.warp_keypoints && !Array.isArray(resp.warp_keypoints)) {
                    Object.keys(resp.warp_keypoints).forEach((kpKey) => {
                        const castedWarpKp = resp.warp_keypoints as WarpKeypoints;
                        localSourceKps = localSourceKps.concat(convertApiKeypointsArray(castedWarpKp[kpKey].source, kpKey));
                        localDestinationKps = localDestinationKps.concat(convertApiKeypointsArray(castedWarpKp[kpKey].destination, kpKey));
                    });
                    setSourceKpList(localSourceKps);
                    setDestinationKpList(localDestinationKps);
                } else if (crosswarpPayload) {
                    if (resp.crosswarp_final_keypoints) {
                        Object.keys(resp.crosswarp_final_keypoints).forEach((kpKey) => {
                            if (resp.crosswarp_final_keypoints) {
                                localSourceKps = localSourceKps.concat(convertApiKeypointsArray(resp.crosswarp_final_keypoints[kpKey].source, kpKey));
                                localDestinationKps = localDestinationKps.concat(
                                    convertApiKeypointsArray(resp.crosswarp_final_keypoints[kpKey].destination, kpKey),
                                );
                            }
                        });

                        crosswarpKeypoints = { destination: localDestinationKps, source: localSourceKps };
                        setSourceKpList(localSourceKps);
                        setDestinationKpList(localDestinationKps);
                    } else {
                        getOnlyCoord(crosswarpPayload, Object.keys(resp.garment_cuts), crosswarpKeypointCallback);
                    }
                }

                // ---- Reserve Handling when the Warp is not reserved ----
                if (!resp.reservations) {
                    putReserve({ clientId: currentClient.id, garmentId: resp.garment_id, modelId: resp.model_id }).catch((err) => {
                        console.error(err);
                    });
                    intervalId = window.setInterval(() => {
                        putReserve({ clientId: currentClient.id, garmentId: resp.garment_id, modelId: resp.model_id }).catch((err) => {
                            console.error(err);
                        });
                    }, 20000);
                }
            }).catch((err) => {
                console.error(err);
            });
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [currentClient]);

    useEffect(() => {
        if (isWarpInitialized) {
            // ---- We fetch the warp ----
            updateWarpWithCurrentState();
        } else {
            // ---- If we Reset or remove too many kp we reset the warpImg and set the active part ----
            setWarpImg(undefined);
            setActivePart('torso');
        }
    }, [isWarpInitialized]);

    useEffect(() => {
        // ---- If we have success false from reserve api or http error we block the edition ----
        if ((reserveData && !reserveData.success) || isReserveError) {
            setBlockEdit(true);
            isReserved = true;
            clearInterval(intervalId);
        }

        // ---- If we have the edition blocked and reservation is a success we authorize the edition ----
        if (blockEdit && reserveData && reserveData.success && isReserved) {
            isReserved = false;
            setBlockEdit(false);
        }
    }, [reserveData, isReserveError]);

    useEffect(() => {
        if (isAnyLoading) {
            document.title = t('page_names.loading', { ns: LAYOUT_LOCALES });
        } else {
            document.title = 'Veesual IGP';
        }
    }, [isAnyLoading]);

    useEffect(() => {
        if (isWarpEditError || isNewWarpEditError) {
            customNavigate('/', undefined, undefined, true);
        }
    }, [isWarpEditError, isNewWarpEditError]);

    useEffect(() => {
        // ---- Remove the Sizing step if we don't have the Cross image ----
        if (data && (!guideData || !data.is_override)) {
            setStepArray(stepArray.filter((localStep) => localStep !== WARPEDIT_STEPS.SIZING));
        }
    }, [data]);

    useEffect(() => {
        let editObject = null;
        let currentObject = null;
        let finalObject = null;

        if (editPayload
            && currentPayload
            && editPayload.transformations.length > 0
            && currentPayload.transformations.length > 0
        ) {
            editObject = {
                dest: editPayload.transformations[0].keypoints_destination,
                source: editPayload.transformations[0].keypoints_source,
                transformations: editPayload.transformations[0].transformations,
            };
        }

        if (currentPayload
            && currentPayload.transformations.length > 0
        ) {
            currentObject = {
                dest: currentPayload.transformations[0].keypoints_destination,
                source: currentPayload.transformations[0].keypoints_source,
                transformations: currentPayload.transformations[0].transformations,
            };
        }

        if (finalPayload
            && finalPayload.transformations.length > 0
        ) {
            finalObject = {
                dest: finalPayload.transformations[0].keypoints_destination,
                source: finalPayload.transformations[0].keypoints_source,
                transformations: finalPayload.transformations[0].transformations,
            };
        }

        // ---- Need to compare stringified object or make a custom compare function -----
        dispatch(setPathBlockState({
            key: pathname,
            newValue: {
                block: stepString !== WARPEDIT_STEPS.FINISH
                    && JSON.stringify(useFinalPayload ? editObject : finalObject) !== JSON.stringify(currentObject),
            }
            ,
        }));
    }, [stepString, useFinalPayload, pathname, editPayload, currentPayload, finalPayload]);

    useEffect(() => {
        if (!visibleFeedbackIds && unsortedFeedbacks && unsortedFeedbacks.items.length > 0) {
            setVisibleFeedbackIds(getVisibleIdsFromFeedbacks(unsortedFeedbacks.items));
        }
    }, [unsortedFeedbacks]);

    useEffect(() => {
        if (unsortedFeedbacks) {
            const apiCurrentPage = parseInt(unsortedFeedbacks.current_page_number, 10);

            if (currentPage === apiCurrentPage && currentPage !== 1) {
                setFullFeedbacks((prev) => prev.concat(unsortedFeedbacks.items));
            } else {
                setCurrentPage(1);
                setFullFeedbacks(unsortedFeedbacks.items);
            }
        }
    }, [unsortedFeedbacks]);

    return (
        <Box height='100%' id="warpEditContainerId" position="relative">
            <InputModal
                description={`${t('comparison_modal.description')}${lastNoSmartReason
                    ? `\n\n${t('comparison_modal.last_reason')} ${lastNoSmartReason}`
                    : ''}
                    `}
                disableCheckboxLabel={lastNoSmartReason ? t('comparison_modal.same_reason') : undefined}
                isOpen={showNoSmartModal}
                onClose={handleOnCloseComparisonModal}
                onValidate={handleComparisonValidation}
                title={t('comparison_modal.title')}
            />
            <FeedbackModal
                hidePriority={true}
                history={feedbacks?.items.filter((feedback) => feedback.status !== FEEDBACK_STATUS.CANCELED)}
                images={currentStepImages}
                modalOpen={feedbackModalOpen}
                onClose={() => { setFeedbackModalOpen(false); }}
                onSend={sendFeedback}
                screenId="warpEditContainerId"
                sendLabel={t('save_and_send')}
            />
            {
                (!isWarpInitialized && !fetchingOnlyCoord)
                && <HStack
                    bgColor="primary.500"
                    bottom={0}
                    position="absolute"
                    width="100%"
                    zIndex="10"
                >
                    <Text
                        color="white"
                        fontWeight="bold"
                        ml={`calc(${33 * (sourceKpList.length > destinationKpList.length ? 1 : 0)}%)`}
                        textAlign="center"
                        width="33%"
                    >
                        {`^ ${t('place_here')} ^`}
                    </Text>
                    <Text
                        color="white"
                        fontWeight="bold"
                        left={'66%'}
                        position="absolute"
                        textAlign="center"
                        width="33%"
                    >
                        {t('init_phase')}
                    </Text>
                </HStack>
            }
            <SuperposeAnimationContext.Provider value={{ animate: animateSuperpose, setAnimate: setAnimateSuperpose }}>
                {
                    data
                        ? <WarpEditSubHeader
                            activePart={activePart}
                            activeTexture={activeTextureKey}
                            allowNoSmart={noSmartPermission}
                            blockEdit={blockEdit}
                            disableCheckResult={!isWarpInitialized || blockEdit}
                            handleActivePartChange={handleActivePartChange}
                            handleDisableSmartwarpCheck={handleDisableSmartwarpCheck}
                            handleDisableUpperfix={handleDisableUpperfix}
                            handleFeedbackClick={() => setFeedbackModalOpen(true)}
                            handlePostSmart={handlePostSmartKp}
                            handleSmartwarpCheck={handleSmartwarpCheck}
                            handleStepChange={changeStep}
                            handleTextureChange={handleTextureChange}
                            handleVisibleCheck={handleVisibleCheck}
                            isComparisonLoading={((!comparisonNoSmartWarp || !comparisonSmartWarp))}
                            isPostWarpError={isPostWarpError}
                            isPostWarpLoading={isPostWarpLoading}
                            isSmartwarpDisabled={isSmartwarpDisabled}
                            isUpperfixDisabled={isUpperfixDisabled}
                            isWarpInitialized={isWarpInitialized}
                            nextStep={() => handleStepChange(step + 1)}
                            stepArray={stepArray}
                            stepString={stepString}
                            textures={textureKeys}
                            useFinalPayload={useFinalPayload}
                            visibleValues={visibleValues}
                            warpData={data}
                        />
                        : <BaseSubHeader />
                }
            </SuperposeAnimationContext.Provider>

            {
                data && data.reservations
                && <ReservedBanner reservations={data.reservations} />
            }

            {
                (data && data.warp_status === PREFILTERS.HASFEEDBACK && stepString !== WARPEDIT_STEPS.FINISH)
                && <>
                    {
                        (feedbacks && feedbacks.items.length !== 0 && warpId)
                        && <HasFeedbackBanner
                            detailLink={warpId ? `/warp/${warpId}/feedbacks` : `/garment/${garmentId}/feedbacks`}
                            feedback={feedbacks.items[0]}
                        />
                    }
                </>

            }

            {
                data
                && <>
                    {
                        stepString === WARPEDIT_STEPS.EDIT

                        && <Grid templateColumns={'repeat(3, 1fr)'}>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                                position="relative"
                            >
                                {
                                    fullFeedbacks.length > 0 && <HStack
                                        position="absolute"
                                        right={4}
                                        spacing={2}
                                        top={1}
                                        zIndex={1}
                                    >
                                        <Text>{t('garment', { ns: COMMON_LOCALES })}</Text>
                                        <Switch
                                            isChecked={(destinationKpList.length >= 2 && fullFeedbacks.length > 0) ? leftViewValue : false}
                                            isDisabled={!(destinationKpList.length >= 2 && fullFeedbacks.length > 0)}
                                            onChange={() => {
                                                setLeftViewValue(!leftViewValue);
                                            }}
                                            size="sm"
                                        />
                                        <Text>{t('feedback.title', { ns: COMMON_LOCALES })}</Text>
                                    </HStack>
                                }

                                {
                                    destinationKpList.length >= 2 && fullFeedbacks.length > 0 && leftViewValue
                                        ? <FeedbackSuperpose
                                            containerStyle={{ pt: 8 }}
                                            feedbacks={fullFeedbacks}
                                            noActionButton={true}
                                            onFeedbackClick={handleFeedbackClick}
                                            onMouseEnterCard={(feedbackId) => setFeedbackIdHovered(feedbackId)}
                                            onMouseLeaveCard={() => setFeedbackIdHovered(undefined)}
                                            onScroll={handleOnScrollFeedbacks}
                                            visibleFeedbackIds={visibleFeedbackIds}
                                        />
                                        : <WarpGridItem
                                            blockEdit={blockEdit}
                                            disableDrag={isKpDrag || eraseMode || eraseIndex.length > 0}
                                            eraseMode={eraseMode}
                                            handleErase={handleErase}
                                            handleMouseUp={handleEraseEnded}
                                            hoveredKp={hoveredKp}
                                            isImageDrag={isImageDrag}
                                            kpList={sourceKpList}
                                            maskSrcs={
                                                editPayload && editPayload.garment_foreground
                                                    ? { base: data.garment_image_url, masks: [editPayload.garment_foreground] }
                                                    : undefined
                                            }
                                            moveAllKpMode={moveAllKpMode}
                                            onWarpClick={handleOnWarpClick}
                                            removeKp={removeKp}
                                            setHoveredKp={setHoveredKp}
                                            setIsImageDrag={setIsImageDrag}
                                            setIsKpDrag={setIsKpDrag}
                                            srcs={cutsSrc}
                                            updateKp={updateKp}
                                            visibleValues={useFinalPayload ? [] : visibleValues}
                                            warpType={SOURCE_TYPE}
                                        />
                                }

                                <Divider orientation='vertical' />
                            </GridItem>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                <VStack w="100%">
                                    <HStack>
                                        <HorizontalSlider
                                            sliderProps={{
                                                defaultValue: warpOpacity,
                                                onChange: setWarpOpacity,
                                                value: warpOpacity,
                                            }}
                                            title="Opacity"
                                        />
                                        <IconButton
                                            aria-label='Undo'
                                            icon={<UndoIcon h="100%" />}
                                            isDisabled={historyUndo.length < 2}
                                            onClick={handleUndo}
                                            variant="ghost"
                                        />
                                        <IconButton
                                            aria-label='Redo'
                                            icon={<UndoIcon h="100%" transform="scaleX(-1)" />}
                                            isDisabled={historyRedo.length === 0}
                                            onClick={handleRedo}
                                            variant="ghost"
                                        />
                                        <DropdownButton
                                            onClick={() => setIsResetOpen(!isResetOpen)}
                                            title={t('reset', { ns: COMMON_LOCALES })}
                                            visible={isResetOpen}
                                        >
                                            {
                                                data.crosswarp_payload
                                                && <>
                                                    <Button
                                                        isDisabled={blockEdit}
                                                        onClick={handleResetFinal}
                                                        variant='link'
                                                    >
                                                        {t('reset.automatic', { ns: WARP_EDIT_LOCALES })}
                                                    </Button>
                                                    {
                                                        data.can_reset_cross
                                                        && <Button
                                                            isDisabled={blockEdit}
                                                            onClick={handleResetCross}
                                                            variant='link'
                                                        >
                                                            {t('reset.cross', { ns: WARP_EDIT_LOCALES })}
                                                        </Button>
                                                    }
                                                </>
                                            }

                                            {
                                                Object.keys(data.garment_cuts).map((cut) => (
                                                    <Button
                                                        isDisabled={blockEdit}
                                                        key={cut}
                                                        onClick={() => handleResetPart(cut)}
                                                        variant='link'
                                                    >
                                                        {
                                                            cut === 'torso'
                                                                ? t('reset.delete_all', { ns: WARP_EDIT_LOCALES })
                                                                : t(`${cut}`, { ns: COMMON_LOCALES })
                                                        }
                                                    </Button>
                                                ))
                                            }
                                        </DropdownButton>
                                        {isAnyLoading && <Spinner />}
                                    </HStack>
                                    <WarpGridItem
                                        blockEdit={blockEdit}
                                        disableDrag={isKpDrag || eraseMode || eraseIndex.length > 0}
                                        eraseMode={eraseMode}
                                        extras={[...extrasSrc, ...(overlayFeedbackImages || [])]}
                                        garmentcuts={
                                            data.model_garmentcuts && !Array.isArray(data.model_garmentcuts)
                                                ? data.model_garmentcuts
                                                : undefined
                                        }
                                        guide={guideData || undefined}
                                        handleErase={handleErase}
                                        handleMouseUp={handleEraseEnded}
                                        hoveredKp={hoveredKp}
                                        isImageDrag={isImageDrag}
                                        kpList={destinationKpList}
                                        modelIdentity={data.model_identity}
                                        moveAllKpMode={moveAllKpMode}
                                        onGarmentcutsChange={handleOnGarmentcutsChange}
                                        onWarpClick={handleOnWarpClick}
                                        opacitySource={warpImg}
                                        opacityValue={warpOpacity}
                                        removeKp={removeKp}
                                        setHoveredKp={setHoveredKp}
                                        setIsImageDrag={setIsImageDrag}
                                        setIsKpDrag={setIsKpDrag}
                                        srcs={[data.model_image_url]}
                                        updateKp={updateKp}
                                        visibleValues={useFinalPayload ? [] : visibleValues}
                                        warpType={DESTINATION_TYPE}
                                    />
                                </VStack>

                                <Divider orientation='vertical' />
                            </GridItem>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                <SuperposeAnimationContext.Provider value={{
                                    animate: typeof carouselData[carouselIndex] !== 'string'
                                        && (carouselData[carouselIndex] as Pose).model_identity.id === data.model_identity?.id,
                                }}>
                                    <CarouselGridElement
                                        imagesData={carouselData}
                                        info={t('tooltip.comparison_images')}
                                        label={comparisonTitle}
                                        onIndexChange={setCarouselIndex}
                                        superpose={warpImg ? [data.model_image_url, warpImg] : undefined}
                                    />
                                </SuperposeAnimationContext.Provider>
                            </GridItem>
                        </Grid>
                    }
                    {
                        stepString === WARPEDIT_STEPS.SMART_COMPARISON
                        && <SuperposeAnimationContext.Provider value={{ animate: animateSuperpose }}>
                            <WarpComparison
                                isPostWarpError={isPostWarpError}
                                itemHeight={GRID_ITEM_HEIGHT}
                                noSmartWarp={comparisonNoSmartWarp}
                                onSelect={
                                    noSmartPermission
                                        ? (newVal) => setIsSmartwarpDisabled(newVal !== 'smart')
                                        : undefined
                                }
                                selectedWarpType={isSmartwarpDisabled ? 'nosmart' : 'smart'}
                                smartWarp={comparisonSmartWarp}
                            />
                        </SuperposeAnimationContext.Provider>
                    }
                    {
                        stepString === WARPEDIT_STEPS.SIZING
                        && <Grid templateColumns={'repeat(2, 1fr)'}>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                <VStack w="100%">
                                    <SingleImageGridElement
                                        imageSrc={(isSmartwarpDisabled ? comparisonNoSmartWarp : comparisonSmartWarp) || ''}
                                        label={t('warp')}
                                    />
                                    {
                                        data.model_identity
                                        && <ModelIdentityBanner
                                            modelHeight={data.model_identity.height}
                                            modelSizes={[data.model_identity.garment_size, data.model_identity.garment_size2]}
                                        />
                                    }

                                </VStack>
                                <Divider orientation='vertical' />
                            </GridItem>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                {
                                    guideData
                                    && <VStack w="100%">
                                        <SingleImageGridElement
                                            imageSrc={guideData.image_url}
                                            label={t('sizing_check')}
                                            superpose={(isSmartwarpDisabled ? comparisonNoSmartWarp : comparisonSmartWarp) || ''}
                                            superposeAnimation={true}
                                            superposeLabels={[t('automatic', { ns: COMMON_LOCALES }), t('warp')]}
                                        />
                                        <ModelIdentityBanner
                                            modelHeight={guideData.model_identity.height}
                                            modelSizes={[guideData.model_identity.garment_size, guideData.model_identity.garment_size2]}
                                        />
                                    </VStack>
                                }

                            </GridItem>
                        </Grid>
                    }
                    {
                        stepString === WARPEDIT_STEPS.FINAL_CHECK
                        && <Grid templateColumns={'repeat(3, 1fr)'}>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                <VStack w="100%">
                                    <SingleImageGridElement imageSrc={finalImg || ''} label={t('final_warp')} />
                                    {
                                        data.model_identity
                                        && <ModelIdentityBanner
                                            modelHeight={data.model_identity.height}
                                            modelSizes={[data.model_identity.garment_size, data.model_identity.garment_size2]}
                                        />
                                    }
                                </VStack>
                                <Divider orientation='vertical' />
                            </GridItem>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                <VStack w="100%">
                                    <OpacityGridElement imageSrc={data.model_image_url} label={t('cover_check')} opacitySrc={warpImg || ''} />
                                    {
                                        data.model_identity
                                        && <ModelIdentityBanner
                                            modelHeight={data.model_identity.height}
                                            modelSizes={[data.model_identity.garment_size, data.model_identity.garment_size2]}
                                        />
                                    }
                                </VStack>

                                <Divider orientation='vertical' />
                            </GridItem>
                            <GridItem
                                display="flex"
                                flexDir="row"
                                height={GRID_ITEM_HEIGHT}
                                justifyContent="center"
                                pb={2}
                            >
                                <CarouselGridElement
                                    imagesData={[...(data.references || []), ...(data.extras || [])]}
                                    label={comparisonTitle}
                                    onIndexChange={setCarouselIndex}
                                />
                            </GridItem>
                        </Grid>
                    }
                    {
                        stepString === WARPEDIT_STEPS.FINISH
                        && <HStack h={GRID_ITEM_HEIGHT} justifyContent="center">
                            {
                                saveData && saveData.success
                                && <>
                                    {(feedbacks && feedbacks.items.length > 0)
                                        ? <HStack h="100%" spacing={0} w="100%">
                                            <VStack flex={2} h="100%" p={'16px 8px 16px 16px'}>
                                                <Text>{t('success_description_feedback')}</Text>
                                                <ImageWithError
                                                    fallback={<Center data-image-url={finalImg} h="100%">
                                                        <Spinner />
                                                    </Center>}
                                                    h="calc(100% - 32px)"
                                                    objectFit="contain"
                                                    src={finalImg}
                                                />
                                            </VStack>

                                            <FeedbackCardsList containerStyle={{ flex: 3, h: '100%', p: '16px 8px 16px 16px' }} warpId={warpId} />
                                        </HStack>
                                        : <SuccessLayout imgSrc={finalImg} text={t('success_description')} />
                                    }
                                </>
                            }
                            {
                                (isPostSaveWarpLoading || isPostSaveNewWarpLoading)
                                && <Spinner />
                            }
                            {
                                (isPostSaveWarpError || isPostSaveNewWarpError || (saveData && saveData.errors))
                                && <Text>{t('error_save_warp', { ns: ERROR_LOCALES })}</Text>
                            }
                        </HStack>
                    }
                </>

            }

        </Box>
    );
}
