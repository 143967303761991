import { Box, Center, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';

import { Garment } from '../../types/api-types';
import { COMMON_LOCALES, PREFILTERS, UNAUTHORIZED_PPQC_STATUS, UNAUTHORIZED_RWQC_STATUS } from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import resizeImage from '../../utils/image';
import useRoles from '../../utils/roles-hook';
import { getCleanSearch } from '../../utils/url-helpers';
import RoundButton from '../buttons/RoundButton';
import ImageWithError from '../ImageWithError';
import Restricted from '../permission/Restricted';
import usePermission from '../permission/usePermission';
import { useProductsContext } from '../products/ProductsContext';

// ---- Props needed to have correct overflow on text in card ----
const TEXT_OVERFLOW_PROPS = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    w: '100%',
};

interface GarmentGridCardProps {
    garment: Garment,
    imageRatio?: string,
    statusKey?: string,
    isReserved?: boolean
}

export default function GarmentGridCard(props: GarmentGridCardProps) {
    const { garment, imageRatio, statusKey, isReserved } = props;

    const { search } = useLocation();
    const navigate = useCustomNavigate();
    const { t } = useTranslation(COMMON_LOCALES);
    const { isAdmin } = useRoles();
    const refwarpReadPermission = usePermission('ref_warp.write', garment.warp_status);
    const refwarpWarpPermission = usePermission('ref_warp.warp', garment.warp_status);

    const [isReservedLocal, setIsReservedLocal] = useState<boolean>(!!isReserved);

    // ---- This is used to change the font size according to the card width ----
    const ref = useRef<HTMLDivElement>(null);
    const [cardWidth, setCardWidth] = useState<number>(0);
    const fontSize = useMemo(() => {
        if (cardWidth < 200) { return 9; }

        return 'md';
    }, [cardWidth]);
    useEffect(() => {
        function handleResize() {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                setCardWidth(rect.width);
            }
        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [ref.current?.getBoundingClientRect()]);

    const ProductContext = useProductsContext();

    const isChecked = useCallback(
        () => {
            let selectProduct = null;

            if (ProductContext?.checkAll) {
                return true;
            }

            if (ProductContext?.selectedProducts) {
                selectProduct = ProductContext?.selectedProducts.find((selectedProduct) => selectedProduct.id === garment.garment_id);

                return !!selectProduct;
            }

            return !!selectProduct;
        },
        [ProductContext],
    );

    // TODO: REMOVE COMMENT WHEN MULTI SELECT
    // const handleCheck = () => {
    //     if (ProductContext?.selectAProduct) {
    //         if (isChecked()) {
    //             return ProductContext?.deselectAProduct(garment.garment_id);
    //         }

    //         return ProductContext?.selectAProduct(garment.garment_id);
    //     }

    //     return null;
    // };

    const warpId = useMemo(() => {
        if (!garment) {
            return null;
        }
        let localWarpId = garment[`warp_${garment.garment_gender?.toLowerCase()}_id`];

        if (!localWarpId) {
            // Choose correct Warp Id
            if (garment.warp_status === garment.warp_male_status) {
                localWarpId = garment.warp_male_id;
            } else if (garment.warp_status === garment.warp_female_status) {
                localWarpId = garment.warp_female_id;
            } else if (garment.warp_status === garment.warp_boy_status) {
                localWarpId = garment.warp_boy_id;
            } else if (garment.warp_status === garment.warp_girl_status) {
                localWarpId = garment.warp_girl_id;
            }
        }

        return localWarpId;
    }, [garment]);

    // ---- If we are not admin and a garment has not the correct warp feedback priority ----
    const feedbackStatusNotAllow = useMemo(() => (
        !!(!isAdmin && garment.warp_feedback_priority && !['CRITICAL', 'IMPROVE'].includes(garment.warp_feedback_priority))
    ),
    [isAdmin, garment]);

    // ---- Manual handling of disabled buttons ----
    const isRWDisabled = useMemo(
        // ---- If the warp is not existing and the garment status doesn't match ----
        () => (!([PREFILTERS.TOWARP, PREFILTERS.WARPQUALITY, PREFILTERS.READY] as string[]).includes(garment.garment_status) && !warpId)
        // ---- Or feedback status doesn't allow ----
        || feedbackStatusNotAllow
        // ---- Or if we don't have the refwarp Warp permission (dynamic according to warp status) ----
        || !refwarpWarpPermission,
        [garment, feedbackStatusNotAllow, refwarpWarpPermission],
    );
    const rwHref = useMemo(() => {
        if (isRWDisabled) {
            return undefined;
        }
        if (warpId) {
            return `/warp/${warpId}/edit?${getCleanSearch(search)}`;
        }

        return `/garment/${garment.garment_id}/warp?${getCleanSearch(search)}`;
    }, [isRWDisabled, warpId, garment]);

    const isCWDisabled = useMemo(() => garment.warp_status !== PREFILTERS.READY, [garment]);
    const cwHref = useMemo(() => {
        if (isCWDisabled) {
            return undefined;
        }

        return `/crosswarp/${warpId}?${getCleanSearch(search)}`;
    }, [isCWDisabled, garment, search]);

    const isPPQCDisabled = useMemo(() => UNAUTHORIZED_PPQC_STATUS.includes(garment.garment_status), [garment]);
    const ppqcHref = useMemo(() => {
        if (isPPQCDisabled) {
            return undefined;
        }

        return `/preprocessing/${garment.garment_id}/quality?${getCleanSearch(search)}`;
    }, [isPPQCDisabled, garment, search]);

    const isRWQCDisabled = useMemo(() => UNAUTHORIZED_RWQC_STATUS.includes(garment.warp_status)
        || !garment.warp_status
        || !garment?.garment_gender
        || !garment[`warp_${garment?.garment_gender.toLowerCase()}_id`]
        || feedbackStatusNotAllow
        // ---- If we don't have the refwarp read permission (dynamic according to the warp status) ----
        || !refwarpReadPermission,
    [garment, feedbackStatusNotAllow, refwarpReadPermission]);
    const rwqcHref = useMemo(() => {
        if (isRWQCDisabled) {
            return undefined;
        }

        if (garment.warp_status === PREFILTERS.MISSINGALLOWED) {
            return undefined;
        }

        return `/refwarp/${warpId}/quality?${getCleanSearch(search)}`;
    }, [isRWQCDisabled, garment, search]);

    useEffect(() => {
        if (isReservedLocal || !isReserved) {
            return;
        }

        setIsReservedLocal(isReserved);
    }, [isReserved]);

    return (
        <VStack
            backgroundColor="gray.100"
            border="2px solid"
            borderColor={isChecked() ? 'primary.500' : 'transparent'}
            borderRadius="lg"
            overflow="hidden"
            padding={4}
            ref={ref}
            w="100%"
        >
            <Box
                borderRadius="lg"
                h={0}
                overflow="hidden"
                paddingBottom={imageRatio}
                position="relative"
                w="100%"
            >
                <LazyLoad height="100%" offset={100} once overflow>
                    <ImageWithError
                        cursor="pointer"
                        fallback={<Center h="100%" position="absolute" width="100%"><Spinner size="lg"/></Center>}
                        height="100%"
                        objectFit={'contain'}
                        // onClick={handleCheck}
                        position="absolute"
                        src={resizeImage(garment.image_url, { width: 800 })}
                        width="100%"
                    />
                </LazyLoad>

                {/* <Checkbox
                    display={'block'}
                    isChecked={isChecked()}
                    left={4}
                    onChange={handleCheck}
                    position="absolute"
                    size="lg"
                    top={4}
                /> */}
            </Box>
            <VStack justifyContent="space-between" spacing={2} w="100%">
                <VStack alignItems="flex-start" maxW="100%" minW={30}>
                    <Text
                        fontSize={fontSize}
                        noOfLines={[1, 2]}
                        textAlign='center'
                        {...TEXT_OVERFLOW_PROPS}
                    >
                        {garment.garment_name}
                    </Text>
                    {
                        isReserved
                            ? <Text
                                fontSize={fontSize}
                                fontWeight="bold"
                                textAlign='center'
                                whiteSpace="nowrap"
                                {...TEXT_OVERFLOW_PROPS}
                                color="red"
                            >
                                {t('reserved')}
                            </Text>
                            : <Text
                                fontSize={fontSize}
                                fontWeight="bold"
                                textAlign='center'
                                whiteSpace="nowrap"
                                {...TEXT_OVERFLOW_PROPS}
                            >
                                {garment[statusKey || 'garment_status']}
                            </Text>
                    }

                </VStack>
                <HStack flex={1} justifyContent="space-evenly" minW={90} spacing={1} w="100%">
                    <Restricted to="preprocessing">
                        <RoundButton
                            href={ppqcHref}
                            isDisabled={isPPQCDisabled}
                            onClick={() => navigate(`/preprocessing/${garment.garment_id}/quality`)}
                            secondLine="QC"
                            text="PP"
                            tooltipLabel={t('ppqc')}
                        />
                    </Restricted>
                    <Restricted to="ref_warp.warp">
                        <RoundButton
                            href={rwHref}
                            isDisabled={isRWDisabled}
                            onClick={() => navigate(
                                warpId ? `/warp/${warpId}/edit` : `/garment/${garment.garment_id}/warp`,
                            )}
                            text="RW"
                            tooltipLabel={t('rw')}
                        />
                    </Restricted>
                    <Restricted to="ref_warp.write">
                        <RoundButton
                            href={rwqcHref}
                            isDisabled={isRWQCDisabled}
                            onClick={() => navigate(`/refwarp/${warpId}/quality`)}
                            secondLine="QC"
                            text="RW"
                            tooltipLabel={t(garment.warp_status === PREFILTERS.MISSINGALLOWED
                                ? `warning_${garment.warp_status.toLowerCase()}` : 'rwqc')}
                        />
                    </Restricted>
                    <Restricted to="crosswarp.internal">
                        <RoundButton
                            href={cwHref}
                            isDisabled={isCWDisabled}
                            onClick={() => navigate(`/crosswarp/${warpId}`)}
                            text="CW"
                            tooltipLabel={t('cw')}
                        />
                    </Restricted>
                </HStack>
            </VStack>
        </VStack>

    );
}
